export default {
  validateFileName(file) {
    let currenttime = this.timerCurrentTime = Date.now()
    // eslint-disable-next-line no-useless-escape
    var file_name = file.name.replace(/[^\w\.]/gi, '');
    file_name = file_name.replace(' ', '');
    let updatedfilename = currenttime + ('_') + (file_name);
    return updatedfilename.toLowerCase();
  },
  updateManualReportFileName(file, inspectionNo) {
    // eslint-disable-next-line no-useless-escape
    var file_name = file.name.replace(/[^\w\.]/gi, '');
    file_name = file_name.replace(' ', '');
    let fileNameArray = file_name.split('.');
    let filename = file_name.substr(0, file_name.lastIndexOf('.' + fileNameArray[fileNameArray.length - 1]))
    let extension = fileNameArray[fileNameArray.length - 1]
    let dateObj= new Date();
    let currenttime = `${dateObj.getHours()}${dateObj.getMinutes()}${dateObj.getSeconds()}`;
    inspectionNo = inspectionNo.replace(/-/gi, '_');
    let updatedfilename = filename + '_' + inspectionNo + '_' + currenttime + '.' + extension;
    return updatedfilename;
  },
}
