import axiosutil from './AxiosCofig.js'
import axios from 'axios';

const API = {
    // permitUserLogin: 'PermitUserLogin',
    permitUserLogin: 'PermitAdminLogin',
    permitUserLogout: 'PermitUserLogout',
    permitCheckPrizerList : 'permitCheckPrizerList',
    permitUserForgotPassword: 'permitUserForgotPassword',
    permitUserResetPassword: 'PermitUserResetPassword',
    PermitHistoryLogs: 'PermitHistoryLogs',
    PermitUsers: 'PermitUsers',
    ReportFileDetails: 'ReportFileDetails',
    GenerateReportBulk: 'GenerateReportBulk',
    PermitUserRegistration: 'PermitUserRegistration',
    permitUserDisable: 'DeactivateUser',
    permitUserActivate: 'ActivateUser',
    permitUserDelete: 'DeleteUser',
    sendUserCredentialsOnMail: 'sendBuildchekLogin',
    updateInternachiStatus: 'updateInterNachiUser',
    sendEmailToUsers: 'sendEmail'
}

export default {
    fetchIPAddress(url) {
        const apiInstance = axios.create({
            baseURL: '/'
        });
        return apiInstance.get(url)
            .then((res) => {
                return Promise.resolve(res);
            }).catch((err) => {
                console.error(err);
            });
    },
    userLogin(payload) {
        return axiosutil.post(API.permitUserLogin, payload);
    },
    permitUserLogout(payload) {
        return axiosutil.post(API.permitUserLogout, payload);
    },
    permitCheckPrizerList(payload) {
        return axiosutil.post('permitCheckPrizerList', payload)
    },
    permitUserForgotPassword(payload) {
        return axiosutil.post(API.permitUserForgotPassword, payload)
    },
    permitUserResetPassword(payload) {
        return axiosutil.post(API.permitUserResetPassword, payload);
    },
    permitUserDisable(payload) {
        return axiosutil.post(API.permitUserDisable, payload);
    },
    permitUserActivate(payload) {
        return axiosutil.post(API.permitUserActivate, payload);
    },
    permitUserDelete(payload) {
        return axiosutil.delete(API.permitUserDelete, payload);
    },
    sendCredentialsOnEmail(payload) {
        return axiosutil.post(API.sendUserCredentialsOnMail, payload);
    },
    updateInterNachiUser(payload){
        return axiosutil.post(API.updateInternachiStatus, payload);
    },
    permitHistoryLogs: (payload) => {
        return axiosutil.post(API.PermitHistoryLogs,payload)
    },
    permitUsersList: (payload) => {
        return axiosutil.post(API.PermitUsers, payload)
    },
    sendEmail: (payload) => {
        return axiosutil.post(API.sendEmailToUsers, payload);    
    },
    ReportFileDetails: (payload) => {
        return axiosutil.post(API.ReportFileDetails,payload)
    },
    GenerateReportBulk: (payload) => {
        return axiosutil.put(API.GenerateReportBulk,payload)
    },
    permitUserRegistration: (payload) => {
        return axiosutil.post(API.PermitUserRegistration, payload)
    },
    getAndEditPermitchekPrice: () => axiosutil.permitGetwithoutloader('PermitCheckPrice'),
    updatePermitCheckPrice: (payload) => axiosutil.permitPut('UpdatePermitCheckPrice',payload),
    addUpdatePermitSubscription: (payload) => {  return axiosutil.permitPost('AddUpdatePermitSubscription', payload)},
    getPermitSubscriptions:() => axiosutil.permitGetwithoutloader('GetPermitSubscriptionsForAdmin'),
    changePermitSubscriptionStatus: (payload) => axiosutil.permitPut('ChangePermitSubscriptionStatus', payload),
    deletePermitSubscription: (SubscriptionId, payload) => axiosutil.permitDelete(SubscriptionId +'/DeletePermitSubscription', payload),
    // pricing
    getpaymentservicetypes: (companyId) => axiosutil.getwithoutloader('PaymentService' + '/' + companyId),
    getPermitPaymentService: () => axiosutil.permitGetwithoutloader('GetPermitPaymentService'),

    validatesignaturecardmerchant: (payload) => axiosutil.permitcheckpost('ValidateSignatureCardMerchant', payload),
    validateauthorizenetservice: (request) => axiosutil.permitcheckpost('ValidateAuthorizedDotNetMerchant', request),
    validatefluidpay: (payload) => axiosutil.permitcheckpost('ValidateFluidPayMerchant',payload),
    permitPaymentService: (data) =>  axiosutil.permitPost('/PermitPaymentService', data),
    permitDefaultPaymentService:(company_payment_service_id) => axiosutil.permitGetwithoutloader(company_payment_service_id + '/PermitDefualtPaymentService'),

}