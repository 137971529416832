<template>
  <footer>
    <div class="footer">
      <div class="container">
        <div class="company-info">
         <div class="footer-logo">
            <img src="../assets/image/footer-logo.png" alt="">
            <div class="footer-desc">
              <p class="p-tag-color">Contact Number</p>
              <a href="tel:+13033172499"> (303) 317-2499</a>
            </div>
         </div>
        </div>
        <div class="live-support-hours">
          <span>Live support Hours</span>
          <p style="margin-top: 15px;" class="p-tag-color">Monday - Friday</p>
          <p class="p-tag-color">8:30 AM - 5:30 PM (MT)</p>
          <p class="p-tag-color">Saturday & Sunday : Emergency Support Only</p>
          <p class="p-tag-color">After Hours By Appointment Only</p>
        </div>
        <div class="support-ticket-hours">
          <span>Support ticket hours</span>
          <p style="margin-top: 15px;" class="p-tag-color">Monday - Friday</p>
          <p class="p-tag-color">8:30 AM - 5:30 PM & 10:30 PM - 7:30 AM (MT)</p>
          <p class="p-tag-color">Saturday & Sunday : Emergency Support Only</p>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
    name:"footer-component"
}
</script>

<style>

</style>
