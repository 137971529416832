<template>
  <form class="dropzone file_upload">
    <div id="dropzone-message" style="display: none">
      <span class="dropzone-title"></span>
      <i class="material-icons icon-60">cloud_upload</i>
      <div class="t2-pixel text-align-center">{{dropzoneText!=undefined ? dropzoneText : 'Browse'}}</div>
    </div>
  </form>
</template> 

<script>
import Dropzone from "dropzone";
import '../../../node_modules/dropzone/dist/dropzone.css'
import S3bucket from "./index";
Dropzone.autoDiscover = false;
export default {
  name: "dropzoneGlobal",
  props: {
    dropzoneFrom: [String],
    IsXlsxFormat: [Boolean],
    bucketName: [String],

    // filetype: [String],
    dropzoneText: [String],
    // filesize: [String],
    // maxFiles: [Number],
    // IsHt5Format: [Boolean],
    // IsTpzFormat: [Boolean],
    // minImageWidth: [Number],
    // minImageHeight: [Number],
    // IsSummaryDropzone: [Boolean],
    // IsFromManualReportPdfUpload: {
    //   type: [Boolean],
    //   default() {
    //     return false;
    //   },
    // },
    // InspectionNo: {
    //   type: [String],
    //   default() {
    //     return "";
    //   },
    // },
  },

  mounted() {
    const vm = this;

    let options = {
      url: "/",

      method: "put",

      sending(file, xhr) {
        let _send = xhr.send;
        xhr.send = () => {
          _send.call(xhr, file);
        };
      },
      parallelUploads: 1,
      acceptedFiles: this.filetype,
      maxFiles: this.maxFiles || 1,
      maxFilesize: parseInt(this.filesize),
      dictCancelUpload: "Cancel",
      addRemoveLinks: true,
      uploadMultiple: false,
      timeout: 1800000,
      header: "",

      dictDefaultMessage: this.dropzoneText || "Browse",

      autoProcessQueue: false,

      accept(file, done) {
        if (vm.dropzone.options.acceptedFiles == "image/*") {
          file.rejectDimensions = function() {
            vm.$emit("onDropzoneImageDimensions");
            vm.dropzone.removeFile(file);
          };
          file.acceptDimensions = function () {
            S3bucket.getSignedURL(
              file,
              vm.bucketName,
              vm.IsFromManualReportPdfUpload,
              vm.InspectionNo
            )
              .then((url) => {
                file.uploadURL = url;
                done();
                setTimeout(() => vm.dropzone.processFile(file));
              })
              .catch((err) => {
                done("Failed to get an S3 signed upload URL", err);
              });
          };
        } else {
          S3bucket.getSignedURL(
            file,
            vm.bucketName,
            vm.IsFromManualReportPdfUpload,
            vm.InspectionNo
          )
            .then((url) => {
              file.uploadURL = url;
              done();
              setTimeout(() => vm.dropzone.processFile(file));
            })
            .catch(err => {
              done("Failed to get an S3 signed upload URL", err);
            });
        }
      }
    };

    this.dropzone = new Dropzone(this.$el, options);

    vm.dropzone.on("processing", file => {
      vm.dropzone.options.url = file.uploadURL;
    });
    vm.dropzone.on("error", (file, response) => {
      if (file.status == "error") {
        response = response.replace(new RegExp("MiB", "g"), "MB");
        vm.$emit("onDropzoneError", response);
        vm.dropzone.removeFile(file);
      }
    });
    vm.dropzone.on("canceled", file => {
      vm.$emit("onDropzoneUploadCancel",file);
    });
    vm.dropzone.on("removedfile", file => {
      vm.$emit("onDropzoneUploadCancel", file);
    });
    vm.dropzone.on("addedfile", file => {
      let format = /[.]/.exec(file.name)
        ? /[^.]+$/.exec(file.name)[0]
        : undefined;
      if (vm.dropzone.options.acceptedFiles == "image/*") {
        if (file.type) {
          var type = file.type.split("/");
          if (type[1] == 'heic') {
            vm.dropzone.removeFile(file);
          }
        }
        if (file.type.startsWith("image/")) {
          vm.$emit("onDropzonefileAddedToUpload");  
        }
      } else if (file.type.includes(vm.dropzone.options.acceptedFiles)) {
        vm.$emit("onDropzonefileAddedToUpload");
      } else if (vm.IsHt5Format && format && format.toLowerCase() == "ht5") {
        vm.$emit("onDropzonefileAddedToUpload");
      } else if (vm.IsHt5Format) {
        vm.$emit(
          "onDropzoneError",
          "Please select .ht5 file to import template"
        );
        vm.dropzone.removeFile(file);
      } else if (
        vm.IsXlsxFormat &&
        format &&
        (format.toLowerCase() == "xlsx" || format.toLowerCase() == "xls")
      ) {
        vm.$emit("onDropzonefileAddedToUpload");
      } else if (vm.IsXlsxFormat) {
        vm.$emit(
          "onDropzoneError",
          "Please select .xlsx or .xls file to import template"
        );
        vm.dropzone.removeFile(file);
      } else if (vm.IsTpzFormat && format && format.toLowerCase() == "tpz") {
        vm.$emit("onDropzonefileAddedToUpload");
      } else if (vm.IsTpzFormat) {
        vm.$emit(
          "onDropzoneError" ,
          "Please select .tpz to import template"
        );
        vm.dropzone.removeFile(file);
      }
    });

      vm.dropzone.on("thumbnail", function(file) {
      file.accepted = true;
      if (file.accepted !== false && file.width && file.height) {
        if (vm.IsSummaryDropzone) {
          if (file.width && file.height) {
            if (
              file.width > vm.minImageWidth ||
              file.height > vm.minImageHeight
            ) {
              file.rejectDimensions();
            } else if (file.acceptDimensions != undefined) {
              file.acceptDimensions();
            }
          }
        } else if (vm.dropzoneFrom == "InspProtocolReportConfiguration") {
          if (file.width && file.height) {
            if (
              file.width != vm.minImageWidth ||
              file.height != vm.minImageHeight
            ) {
              file.rejectDimensions();
            } else if (file.acceptDimensions != undefined) {
              file.acceptDimensions();
            }
          }
        } else if(vm.dropzoneFrom.indexOf('agreement-Banner') != -1){
          if (file.width && file.height) {
            if (
              file.width != vm.minImageWidth ||
              file.height != vm.minImageHeight
            ) {
              file.rejectDimensions();
            } else if (file.acceptDimensions != undefined) {
              file.acceptDimensions();
            }
          }
        } else if (file.acceptDimensions != undefined) {
          file.acceptDimensions();
        }
      }
    });

      vm.dropzone.on("complete", file => {
      if (file.status == "success") {
        var ul = file.uploadURL.split("?AWSAccessKeyId");
        var ImageURL = ul[0];
        file.uploadURL = unescape(ImageURL);
        if (
          ["document", "bucketInspectionProtocol"].includes(vm.dropzoneFrom)
        ) {
          vm.$emit("onDropzoneFileSize", file);
        }
        vm.$emit("onDropzoneFileUploaded", ImageURL); 
      }
    });
  },
  methods: {
    resetDropzone() {
      this.dropzone.removeAllFiles(true);
    }
  },
};
</script>
