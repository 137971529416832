<template>
  <section class="page-min-height">
    <div class="login-bg">
      <div class="overlay"></div>
      <div class="login-stap" id="LoginShow" v-if="isShowLoginDialog">
        <div class="container fit-content">
          <div class="login-form">
            <div class="login-logo text-center">
              <img
                class="app-logo-height"
                src="./../assets/image/new_logo.svg"
                alt=""
                height="100px"
                width="200px"
              />
            </div>
            <form v-on:submit.prevent="onSubmitLogin()">
              <h3
                class="font-weight-bold"
                style="font-size: 25px; padding-bottom: 15px"
              >
                Login
              </h3>
              <p style="padding: 10px 0px;color: black;">
                Enter your email address and password to access your account.
              </p>
              <div class="form-group">
                <label for="">Email</label>
                <input type="text" v-model="loginEmail" class="form-control" />
                <p class="text_red_normal" v-if="msg.login_email">
                  {{ msg.login_email }}
                </p>
              </div>
              <div
                class="form-group position-relative"
                style="padding-top: 20px;position: relative;"
              >
                <label for="">Password</label>
                <input
                  v-model="loginPassword"
                  :type="inputtype"
                  class="form-control showhide-password"
                />
                <p class="text_red_normal" v-if="msg.login_password">
                  {{ msg.login_password }}
                </p>
                <a @click="togglepassword"
                  class="password-icon"
                  v-if="inputtype == 'password'"
                >
                  <img src="./../assets/image/password_hide.svg" alt="" />
                </a>
                <a @click="togglepassword"
                  class="password-icon"
                  v-if="inputtype == 'text'"
                >
                  <img src="./../assets/image/password_show.svg" alt="" />
                </a>
              </div>
              <br />
              <div class="form-group text-center">
                <a
                  @click="
                    isShowForgotPasswordDialog = true;
                    isShowLoginDialog = !isShowLoginDialog;
                    msg.forgot_email = ''
                  "
                  class="text_green_normal cursor-pointer"
                  id="ForgotPassword"
                  >Forgot Password?</a
                >
              </div>
              <br />
              <div class="form-group text-center">
                <button type="submit" class="btn btn_dark_blue m-b-10">
                  LOGIN
                </button>
                <!-- <button
                  type="button"
                  @click="backToHome"
                  class="btn btn_dark_blue"
                >
                  BACK TO HOME
                </button> -->
              </div>
              <br /><br />
              <!-- <div class="form-group text-center">
                <p class="p-tag-color">
                  Don't have an account?
                  <a
                    @click="openSignInDialog"
                    class="text_green_bold cursor-pointer"
                    id="SignupShow"
                    ><b>Sign Up</b></a
                  >
                </p>
              </div> -->
            </form>
          </div>
        </div>
      </div>

      <div
        class="forgot-stap"
        id="ForgotPassShow"
        v-if="isShowForgotPasswordDialog"
      >
        <div class="container fit-content">
          <div class="login-form">
            <div class="login-logo text-center">
              <img style="height: 100px;width: 200px;"
                class="app-logo-height"
                src="./../assets/image/new_logo.svg"
                alt=""
              />
            </div>
            <form action="" v-on:submit.prevent="onSubmitForgotPassword()">
              <h3 class="font-weight-bold" style="font-size: 25px; padding-bottom: 15px">Forgot Password?</h3>
              <p style="padding-bottom: 10px;color: black;">
                Please enter your registered email address to reset your
                password.
              </p>
              <div class="form-group">
                <label for="">Email</label>
                <input type="text" v-model="forgotEmail" class="form-control" />
                <p class="text_red_normal" v-if="msg.forgot_email">
                  {{ msg.forgot_email }}
                </p>
              </div>
              <div class="form-group text-center" style="margin-top: 20px;">
                <button type="submit" class="btn btn_dark_blue">SUBMIT</button>
              </div>
              <div class="form-group text-center" style="margin-top: 20px;">
                <button
                  @click="backToLogin"
                  type="button"
                  class="btn btn_dark_blue"
                >
                  BACK TO LOGIN
                </button>
              </div><br />
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: "Log-In",
  watch: {
    loginEmail: function (newval) {
      this.checkValidation(newval, "login_email");
    },
    loginPassword: function (newval) {
      this.checkValidation(newval, "login_password");
    },
      forgotEmail: function (newval) {
      this.checkValidation(newval, "forgot_email");
    },
  },
  data: function () {
    return {
      loginEmail: "",
      loginPassword: "",
      forgotEmail: "",
      msg: [],
      ip: "",
      inputtype: "password",
      isShowForgotPasswordDialog: false,
      isShowLoginDialog: true,

    };
  },
  mounted: function () {
    //   let params = this.$route.params;
    //   if (params.isfromsignUP) {
    //     this.openSignInDialog();
    //   }
    this.$store
      .dispatch("fetchIPAddress", "https://httpbin.org/ip")
      .then((result) => {
        if (
          result &&
          result.data &&
          result.data.origin &&
          result.data.origin.includes(",") &&
          result.data.origin.split(",").length &&
          result.data.origin.split(",")[0]
        ) {
          this.ip = result.data.origin.split(",")[0];
        } else {
          this.ip = result.data.origin;
        }
      })
      .catch(function (error) {
        console.log(error);
      });

    //    // Call from HIT Superadmin
    //   let obj = this.$route.query;
    //   if(obj.emailId && obj.Password)
    //   {
    //     this.loginEmail = decodeURIComponent(obj.emailId);
    //     this.loginPassword = decodeURIComponent(obj.Password);
    //     this.$store.state.isFromHIT = true;
    //     this.onSubmitLogin();
    //   }
  },
  methods: {
    togglepassword() {
      this.inputtype = this.inputtype == "password" ? "text" : "password";
    },
    onSubmitLogin() {
      if (!this.loginEmail || !this.loginPassword) {
        let error = {};
        if (!this.loginEmail) {
          error.login_email = "The email field is required";
        }
        if (!this.loginPassword) {
          error.login_password = "The password field is required";
        }
        this.msg = error;
      } else {
        if (!this.msg.login_email) {
          let payload = {
            username: this.loginEmail,
            password: this.loginPassword,
            device_type: "W",
            ip_address: this.ip,
          };
          this.$store
            .dispatch("userLogin", payload)
            .then((response) => {
              if (response.res == "0") {
                // if(response && response.data && response.data.user_type == 1){
                  if(response && response.data){
                  this.$router.push({ name: "permitCheckTransaction" });
                }else{
                  this.$swal({ title: "", text: 'Invalid credentials.', icon: "error" });
                }
              } else {
                this.$swal({ title: "", text: response.msg, icon: "error" });
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        } else {
          this.$swal({
            title: "",
            text: "Please enter valid email address",
            icon: "error",
          });
        }
      }
    },
    resetData() {
      this.loginEmail = "", 
      this.loginPassword = "", 
      this.msg = [];
      this.forgotEmail = "";
    },
    checkValidation(value, field) {
      if (!value) {
        this.msg[field] = "The field is required";
      } else if (
        field == "email_address" ||
        field == "login_email" ||
        field == "forgot_email"
      ) {
        if (
          value
            .toLowerCase()
            .match(
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            )
        ) {
          this.msg[field] = "";
        } else {
          this.msg[field] = "The field must be a valid email address";
        }
      } else if (field == "mobilenumber") {
        var phoneno = /^\d{10}$/;
        if (value.match(phoneno)) {
          this.msg[field] = "";
        } else {
          this.msg[field] = "The field must contain 10 digit mobile number";
        }
      } else {
        this.msg[field] = "";
      }
    },
    backToLogin() {
      this.isShowForgotPasswordDialog = false;
      this.isShowLoginDialog = true;
      this.resetData();
    },
    onSubmitForgotPassword() {
      if (!this.forgotEmail) {
        let error = {
          forgot_email: "The email field is required",
        };
        this.msg = error;
      } else {
        if (!this.msg.forgot_email) {
          let payload = {
            email_address: this.forgotEmail,
          };
          this.$store
            .dispatch("permitUserForgotPassword", payload)
            .then((response) => {
              if (response.res == "0") {
                this.backToLogin();
                this.$swal({
                  title: "",
                  text: response.msg,
                  icon: "success",
                });
              } else {
                this.$swal({ title: "", text: response.msg, icon: "error" });
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        } else {
          this.$swal({
            title: "",
            text: "Please enter valid email address",
            icon: "error",
          });
        }
      }
    },
  },
};
</script>
