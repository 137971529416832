import axios from 'axios'
import validate from '../../common-plugin/validation'
export default {
  getSignedURL(file, bucketname, isManualReportUpload = false, inspectionNo = '') {
    const apiInstance = axios.create({
      baseURL: 'https://pl1sbc2643.execute-api.us-west-2.amazonaws.com/prod',
      headers: {
        'x-api-key': 'pM3cUDoWcj91TAGsPzfcs1KrbbvP1RNo5QkNp6sH',
      }
    });
    let updatedfilename = '';
    if (isManualReportUpload) {
      updatedfilename = validate.updateManualReportFileName(file,inspectionNo);
    } else {
      updatedfilename = validate.validateFileName(file);
    }
    let endpoint = process.env.S3baseURL
    let payload = {
      filePath: updatedfilename,
      contentType: file.type,
      bucketName: bucketname,
    }
    return apiInstance.post(endpoint, payload)
      .then((res) => {
        var ImageURL = res.data.url
        return Promise.resolve(ImageURL || '/')
      })
      .catch((err) => {
        console.error(err)
        return Promise.reject('/')
      })
  },
};