<template>
  <div class="container is-fluid text-center page-min-height">
    <div class="column">
      <p style="font-size: 8pc">401</p>
      <div>
        <p style="font-size: 2pc">ERROR!</p>
        <p class="p-tag-color">
          You have attempted to access a page that you are
          <b>Not Authorized</b> to view.
        </p>
        <p class="p-tag-color">If have any questions, please contact the administrator.</p>
        <div style="display: inline-block">
          <a
            style="color: #1a7592"
            href="javascript:void(0)"
            @click="loginAgain"
            >login again</a
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name:'un-authorized',
  methods: {
    loginAgain: function () {
      this.$router.push({ name: "/" });
    },
  },
};
</script>