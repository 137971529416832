<template>
  <div class="full-container">
    <div class="full-container-panel">
      <div class="columns transactions-header width-100">
        <div class="column is-2 inline-block panel-collapse-header-text width-20">
          <span class="t4-pixel">Buildchek History</span>
        </div>
        <div class="column is-10 float-right width-80 penal-hd">
          <div class="columns inline-flex transaction-filter">
            <div class="column is-6 m-r datepicker placeholderColor line-white width-210 input-focus">
              <md-input-container class="theme-white" style="min-height: 0px !important;padding-top: 5px !important;">
                <DateRange
                  :startDate="start"
                  :endDate="end"
                  class="md-select choices md-theme-default custom_datepicker"
                  @update-date="updateDate"
                  @clear-date="clearDate"
                  dateFormat="MM-DD-YYYY"
                >
                </DateRange>
              </md-input-container>
            </div>
            <div class="column is-6 m-r">
              <div class="width-210 line-white search-color-input input-focus">
                <md-input-container class="theme-white" style="min-height: 0px !important;padding-top: 5px !important;">
                  <md-icon>
                    <magnify-icon title="Search" />
                  </md-icon>
                  <md-input
                    placeholder="Search Request"
                    v-model.trim="search"
                    @input="searchData"
                    type="text"
                  >
                  </md-input>
                  <span
                    class="md-icon md-theme-white material-icons"
                    @click="clearSearch"
                  >
                    <md-icon>
                      <close-icon :title="tipclear" />
                    </md-icon>
                  </span>
                </md-input-container>
              </div>
            </div>
          </div>
        </div>
      </div>

      <md-table class="width-100">
        <md-table-header v-if="permitHistoryLogs.length">
          <md-table-row>
            <md-table-head>User name</md-table-head>
            <md-table-head md-numeric>Created Date</md-table-head>
            <md-table-head md-numeric>Property Address</md-table-head>
            <md-table-head md-numeric>Description</md-table-head>
            <md-table-head md-numeric>Result</md-table-head>
            <md-table-head md-numeric>Payment</md-table-head>
            <md-table-head md-numeric>Logs</md-table-head>
          </md-table-row>
        </md-table-header>

        <md-table-body v-if="permitHistoryLogs.length">
          <md-table-row
            v-for="row in permitHistoryLogs"
            :key="row.permit_log_id"
          >
            <md-table-cell>
              <span v-if="row.first_name || row.last_name">
                {{ row.first_name + " " + row.last_name }}
              </span>
              <span v-else>-</span>
            </md-table-cell>

            <md-table-cell>
              <span v-if="row.created_date">
                {{ row.created_date | FormatFullDateToMMDD }}
              </span>
              <span v-else>-</span>
            </md-table-cell>

            <md-table-cell>
              <span v-if="row.property_address">
                {{ row.property_address }}
              </span>
              <span v-else>-</span>
            </md-table-cell>

            <md-table-cell>
              <span v-if="row.description">
                {{ row.description }}
              </span>
              <span v-else>-</span>
            </md-table-cell>

            <md-table-cell>
              <span class="text-green" v-if="row.is_found"> Found </span>
              <span class="has-text-danger" v-else>Not found</span>
            </md-table-cell>

            <md-table-cell>
              <span class="text-green" v-if="row.is_payment"> Success </span>
              <span class="has-text-danger" v-else>-</span>
            </md-table-cell>

            <md-table-cell>
              <i
                class="fa-solid fa-circle-info fa-fa-icon"
                @click="openPermitDialog('permit-log-dialog', row)"
              >
                <md-tooltip style="background-color: black;"
                  md-direction="bottom"
                  class="common-tooltip multi-line"
                  >View log</md-tooltip
                >
              </i>
            </md-table-cell>
          </md-table-row>
        </md-table-body>

        <md-table-body v-if="permitHistoryLogs.length == 0">
          <md-table-cell class="no-data"
            >No permit history available.</md-table-cell
          >
        </md-table-body>
      </md-table>

       <div class="text-align-center" v-show="permitHistoryLogs.length > 0">
          <paginate ref="refPagintationHistory" :page-count="numberOfPage" :prev-text="prev" :next-text="next"
            :container-class="pagination" :click-handler="clickCallback" :initial-page="0">
          </paginate>
        </div>

      <div v-show="permitHistoryLogs.length" class="d-flex t-history" style="padding-bottom: 20px;">
        <div class="has-text-left width-100">
          Total results {{ $store.state.total_logs }}
        </div>
      </div>
    </div>

    <md-dialog
      v-if="openedDialog == 'permit-log-dialog'"
      ref="permit-log-dialog" @open="disablescrolling"
      @close="enablescrolling"
    >
      <md-dialog-title class="md-dialog-custom-title" style="margin: 0px;">
        <div class="columns center-decoration">
          <div class="column d-contents">Logs</div>
          <div class="column pull-right">
            <md-button
              @click="closePermitLogDialog('permit-log-dialog')"
              class="md-raised md-primary"
            >
              Close
            </md-button>
          </div>
        </div>
      </md-dialog-title>
      <md-dialog-content style="padding: 0px">
        <md-table class="width-100">
          <md-table-header>
            <md-table-row>
              <md-table-head>No.</md-table-head>
              <md-table-head>Date</md-table-head>
              <md-table-head>Request API</md-table-head>
              <md-table-head>Status</md-table-head>
            </md-table-row>
          </md-table-header>

          <md-table-body>
            <md-table-row
              v-for="(data, i) in permitLogData.permit_api_logs"
              :key="data.permit_api_log_id"
            >
              <md-table-cell
                ><span>{{ i + 1 }}</span></md-table-cell
              >
              <md-table-cell
                ><span>{{
                  data.created_date | FormatFullDateToMMDD
                }}</span></md-table-cell
              >
              <md-table-cell
                ><span>{{ data.api_name }}</span></md-table-cell
              >
              <md-table-cell
                ><span>{{
                  data.status ? "Found" : "Not Found"
                }}</span></md-table-cell
              >
            </md-table-row>
          </md-table-body>
        </md-table>
      </md-dialog-content>
    </md-dialog>
  </div>
</template>

<script>
import moment from "moment";
import Paginate from "vuejs-paginate";
import DateRange from '../dateRange/Daterangenew.vue'

export default {
  name: "permit-check-history",
  components: {
    Paginate,
    DateRange
  },
  data() {
    return {
      PageRecord: 10,
      numberOfPage: 0,
      prev: "Prev",
      next: "Next",
      pagination: "pagination",
      PageNo: 1,
      RecordsPerPage: 10,
      showLoader: "none",

      tipclear: "Clear Search",
      search: "",
      start: moment().startOf("week"),
      end: moment().endOf("week"),
      search_status: "",
      start_date: "",
      end_date: "",
      searchInterval: null,
      openedDialog: "",
      permitLogData: {},
    };
  },
  computed: {
    permitHistoryLogs() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.numberOfPage = Math.ceil(
        this.$store.state.total_logs / this.PageRecord
      );
      return this.$store.state.permitHistoryLogs;
    },
  },
  mounted: function () {
    this.GetPermitHistoryLogs();
  },
  methods: {
    openPermitDialog(ref, data) {
      if (data.permit_api_logs && data.permit_api_logs.length) {
        this.permitLogData = data;
        this.openDialog(ref);
      } else {
        this.openGlobalSnackbar("No log available.");
      }
    },
    openDialog(ref) {
      this.openedDialog = ref;
      setTimeout(() => {
        this.$refs[ref].open();
      }, 100);
    },
    closePermitLogDialog(ref) {
      this.closeDialog(ref);
      this.permitLogData = {};
    },
    closeDialog(ref) {
      this.$refs[ref].close();
      this.openedDialog = "";
    },
    searchData: function () {
      clearInterval(this.searchInterval);
      this.searchInterval = setInterval(() => {
        clearInterval(this.searchInterval);
        this.refresh();
        this.GetPermitHistoryLogs();
      }, 500);
    },
    clearSearch: function () {
      this.search = "";
      this.refresh();
      this.GetPermitHistoryLogs();
    },

    clickCallback: function (PageNo) {
      this.PageNo = PageNo;
      this.GetPermitHistoryLogs();
    },
    GetPermitHistoryLogs() {
      let payload = {
        PageNo: this.PageNo,
        PageRecord: this.PageRecord,
        search: this.search,
        start_date:
          this.start_date || moment().startOf("week").format("MM-DD-YYYY"),
        end_date: this.end_date || moment().endOf("week").format("MM-DD-YYYY"),
      };
      this.$store
        .dispatch("permitHistoryLogs", {
          payload,
        })
        .then((response) => {
          if (response.res == 1) {
            this.openGlobalSnackbar(response.msg);
          }
        });
    },
    refresh() {
      this.PageNo = 1;
      if (this.$refs.refPagintation) {
        this.$refs.refPagintation.selected = 0;
      }
    },
    updateDate: function (data) {
      var dat = data.split(" - ");
      this.start_date = dat[0];
      this.end_date = dat[1];
      this.refresh();
      this.GetPermitHistoryLogs();
    },
    clearDate: function () {
      this.start_date = "";
      this.end_date = "";
      this.GetPermitHistoryLogs();
    },
  },
  beforeDestroy() {
    this.searchInterval && clearInterval(this.searchInterval);
  },
};
</script>

<style scoped>
input.md-input::placeholder {
  color: #fff !important;
}
</style>
