<template>
  <div class="header">
    <img src="../assets/image/newlogo.png" alt="">
    <input type="checkbox" name="" id="check">
    <label for="check" class="icons">
      <i class="fa-solid fa-bars" id="menu-icon"></i>
      <i class="fa-solid fa-x" id="close-icon"></i>
    </label>
    <div class="navbar">
      <router-link :to='{name:"permitCheckTransaction"}' activeClass="active">
        Buildchek Transaction
      </router-link>
      <router-link :to='{name:"permitCheckPricing"}' activeClass="active">
        Buildchek Pricing
      </router-link>
      <router-link :to='{name:"permitCheckUsers"}' activeClass="active">
        Buildchek Users
      </router-link>
      <router-link :to='{name:"history"}' activeClass="active">
        Buildchek History
      </router-link>
      <router-link :to='{name:"sendEmail"}' activeClass="active">
        Email
      </router-link>
      <!-- <router-link :to='{name:"PermitCheckInvoices"}' activeClass="active">
        Invoices
      </router-link> -->
      <!-- <router-link :to='{name:"ticket"}' activeClass="active">
        Support Ticket
      </router-link> -->
      <a class="logout" @click="onSubmitLogout">Logout</a>
    </div>
  </div>
</template>

<script>
export default {
  name: "header-component",
  data() {
    return {};
  },
  methods: {
    onSubmitLogout() {
      var token = "";
      token = localStorage.getItem(btoa(btoa("permitAuthKey")));
      token = atob(atob(token));
      let payload = {
        token: token,
      };
      this.$store
        .dispatch("permitUserLogout", payload)
        .then((response) => {
          if (response.res == "0") {
            this.$router.push({ name: "account" });
            this.$swal({
              title: "",
              text: " You are logged out successfully.",
              icon: "success",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 5000,
            });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
};
</script>

<style></style>
