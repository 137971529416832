import axios from 'axios';
import { show, hide } from './../assets/js/common.js'
var error_messages = ['Your session is expired. Please login into system again.', 'User has been removed from the system, please login with another user.'];
// var newbaseURL = "https://devapi.homeinspectortech.com"

// Anil code dev server
// const newbaseURL = 'http://52.12.22.38:5050';

// live server
const newbaseURL = 'https://api.buildchek.com';

function redirectToHomepage(error) {
    if (error &&
        ((error.response && error.response.data && error_messages.includes(error.response.data.msg))
            || (error.response && error.response.status == 401))
    ) {
        this.clearSession(); // Logout
    }
    else if (error == true) { // If TRUE then Redirect to HomePage Directly / Logout
        this.clearSession()
    }
}

export default {
    post(uri, requestData, json) {
        if (json) {
            requestData = JSON.stringify(requestData);
        }
        return new Promise((resolve, reject) => {
            show();
            axios.post(uri, requestData)
                .then(function (response) {
                    hide();
                    resolve(response.data);
                })
                .catch(function (error) {
                    hide();
                    reject(error);
                });
        });
    },
    put(uri, requestData) {
        return new Promise((resolve, reject) => {
            show();
            axios.put(uri, requestData)
                .then(function (response) {
                    hide();
                    resolve(response.data);
                })
                .catch(function (error) {
                    hide();
                    reject(error);
                });
        });
    },
    delete(uri, requestData) {
        return new Promise((resolve, reject) => {
            show();
            axios.delete(uri, { data: requestData })
                .then(function (response) {
                    hide();
                    resolve(response.data);
                })
                .catch(function (error) {
                    hide();
                    reject(error);
                });
        }); 
    }, 
    setSession(response) {
        if (response) {
            localStorage.setItem(btoa(btoa('permitUserID')), btoa(btoa(response.data.user_id)));
            localStorage.setItem(btoa(btoa('permitSession')), btoa(btoa(JSON.stringify(response.data))));
            localStorage.setItem(btoa(btoa('permitAuthKey')), btoa(btoa('Bearer ' + response.token)));
        }
    },
    clearSession() {
        localStorage.clear();
    },
    permitGetwithoutloader(uri) {
        const permitInstance = axios.create({
            baseURL: newbaseURL + '/permit_check/API/v1/',
            headers: {
                'Authorization': atob(atob(localStorage.getItem(btoa(btoa("permitAuthKey")))))
            }
        });
        return new Promise((resolve, reject) => {
            permitInstance.get(uri)
                .then(function (response) {
                    if (response.data && error_messages.includes(response.data.msg)) {
                        redirectToHomepage(true);
                    }
                    else
                        resolve(response.data);
                })
                .catch(function (error) {
                    redirectToHomepage(error);
                    reject(error);
                });
        });
    },
    permitPut(uri, requestData) {
        const permitInstance = axios.create({
            baseURL: newbaseURL + '/permit_check/API/v1/',
            headers: {
                'Authorization': atob(atob(localStorage.getItem(btoa(btoa("permitAuthKey")))))
            }
        });
        return new Promise((resolve, reject) => {
            show();
            permitInstance.put(uri, requestData)
                .then(function (response) {
                    hide();
                    if (response.data && error_messages.includes(response.data.msg)) {
                        redirectToHomepage(true);
                    }
                    else
                        resolve(response.data);
                })
                .catch(function (error) {
                    hide();
                    redirectToHomepage(error);
                    reject(error);
                });
        });
    },
    permitPost(uri, requestData) {
        const permitInstance = axios.create({
            baseURL: newbaseURL + '/permit_check/API/v1/',
            headers: {
                'Authorization': atob(atob(localStorage.getItem(btoa(btoa("permitAuthKey")))))
            }
        });
        return new Promise((resolve, reject) => {
            show();
            permitInstance.post(uri, requestData)
                .then(function (response) {
                    hide();
                    if (response.data && error_messages.includes(response.data.msg)) {
                        redirectToHomepage(true);
                    }
                    else
                        resolve(response.data);
                })
                .catch(function (error) {
                    hide();
                    redirectToHomepage(error);
                    reject(error);
                });
        });
    },
    permitDelete(uri) {
        const permitInstance = axios.create({
            baseURL: newbaseURL + '/permit_check/API/v1/',
            headers: {
                'Authorization': atob(atob(localStorage.getItem(btoa(btoa("permitAuthKey")))))
            }
        });
        return new Promise((resolve, reject) => {
            show();
            permitInstance.delete(uri)
                .then(function (response) {
                    hide();
                    if (response.data && error_messages.includes(response.data.msg)) {
                        redirectToHomepage(true);
                    }
                    else
                        resolve(response.data);
                })
                .catch(function (error) {
                    hide();
                    redirectToHomepage(error);
                    reject(error);
                });
        });
    },
    //pricing   
    getwithoutloader(uri) {
        const permitInstance = axios.create({
            baseURL: newbaseURL + '/API/v1/',
            headers: {
                'Authorization': atob(atob(localStorage.getItem(btoa(btoa("permitAuthKey")))))
            }
        });
        return new Promise((resolve, reject) => {
            permitInstance.get(uri)
                .then(function (response) {
                    if (response.data && error_messages.includes(response.data.msg)) {
                        redirectToHomepage(true);
                    }
                    else
                        resolve(response.data);
                })
                .catch(function (error) {
                    redirectToHomepage(error);
                    reject(error);
                });
        });
    },
    permitcheckpost(uri, requestData) {
        const permitInstance = axios.create({
            baseURL: newbaseURL + '/API/v1/',
            headers: {
                'Authorization': atob(atob(localStorage.getItem(btoa(btoa("permitAuthKey")))))
            }
        });
        return new Promise((resolve, reject) => {
            permitInstance.post(uri, requestData)
                .then(function (response) {
                    if (response.data && error_messages.includes(response.data.msg)) {
                        redirectToHomepage(true);
                    }
                    else
                        resolve(response.data);
                })
                .catch(function (error) {
                    redirectToHomepage(error);
                    reject(error);
                });
        });
    },
};

axios.interceptors.request.use(config => {
    if (localStorage[btoa(btoa('permitAuthKey'))]) {
        var token = '';
        token = localStorage[btoa(btoa('permitAuthKey'))];
        config.headers.common['Authorization'] = atob(atob(token));
    }
    let ip = newbaseURL;
    config.headers.common.crossDomain = true;
    config.headers.common.withCredentials = true;
    config.headers.common['TokenType'] = 'web';
    console.log(ip, config.url)
    // Rahul code start (filter in permit-check report)
    // let url = config.url.split("/")[0];
    // if (url == "permitList") {
    //     config.url = ip + '/permit_check/API/v2/' + config.url;
    // }
    // else {
    //     config.url = ip + '/permit_check/API/v1/' + config.url;
    // }
    //  config.url = 'https://api.homeinspectortech.com/permit_check/API/v1/' + config.url;
    // Anil code
    config.url  = ip + `/permit_check/API/v1/${config.url}`;
    return config;
});