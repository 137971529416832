<template>
  <div class="modal-mask">
    <div class="modal-container">
      <div class="modal-header">
        <h3>{{ title }}</h3>
      </div>
      <div class="modal-body" >
        <p style="color: black !important;">{{ description }}</p>
      </div>
      <div class="modal-footer">
        <button class="modal-cancel" @click="cancel">Cancel</button>
        <button class="modal-ok" @click="confirm">OK</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    description: String,
  },
  methods: {
    cancel() {
      this.$emit('cancel');
    },
    confirm() {
      this.$emit('confirm');
    },
  },
};
</script>

<style>
.modal-mask {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Set a high z-index */
}

.modal-container {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  max-width: 400px;
}

.modal-header {
  text-align: center;
  margin-bottom: 20px;
}

.modal-body {
  text-align: center;
  color: black;
}

.modal-footer {
  text-align: center;
  margin-top: 20px;
}

.modal-cancel,
.modal-ok {
  padding: 8px 20px;
  margin: 0 10px;
  border-radius: 5px;
  cursor: pointer;
}

.modal-cancel {
  background-color: #e74c3c;
  color: white;
}

.modal-ok {
  background-color: #2ecc71;
  color: white;
}
</style>

