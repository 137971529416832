<template>
  <div class="login-bg">
    <div class="overlay"></div>
    <div class="forgot-stap" id="ForgotPassShow" style="width: 40%;">
      <div class="container">
        <div class="login-form">
          <div class="login-logo text-center">
            <img style="height: 100px;width: 200px;" class="app-logo-height" src="./../assets/image/new_logo.svg" alt="" />
          </div>
          <form action="" v-on:submit.prevent="onResetPassword()">
            <h3 class="font-weight-bold" style="padding-bottom: 10px;">Reset Password</h3>
            <div class="form-group">
              <label for="">New password</label>
              <input
                v-model="newPassword"
                type="password"
                class="form-control"
              />
              <p class="text_red_normal" v-if="errorMsg1">
                {{ errorMsg1 }}
              </p>
            </div>
            <div class="form-group"><br/>
              <label for="">Confirm password</label>
              <input
                v-model="confirmPassword"
                type="password"
                class="form-control"
              />
              <p class="text_red_normal" v-if="errorMsg2">
                {{ errorMsg2 }}
              </p>
            </div>
            <div class="form-group text-center"><br/>
              <button type="submit" class="btn btn_dark_blue">
                RESET PASSWORD
              </button>
            </div><br/>
            <div class="form-group text-center">
              <button
                type="submit"
                @click="onBacktoLogin"
                class="btn btn_dark_blue"
              >
                BACK TO LOGIN
              </button>
            </div><br/><br/>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ResetPassword",
  watch: {
    newPassword: function (newval) {
      if (!newval) {
        this.errorMsg1 = "The password field is required";
      } else {
        this.errorMsg1 = "";
      }
    },
    confirmPassword: function (newval) {
      if (!newval) {
        this.errorMsg2 = "The passsword field is required";
      } else if (this.newPassword && newval != this.newPassword) {
        this.errorMsg2 = "New password and confirm password are not matching";
      } else {
        this.errorMsg2 = "";
      }
    },
  },
  props: {
    resetPasswordToken: {
      type: [String],
      default() {
        return "";
      },
    },
  },
  data: function () {
    return {
      newPassword: "",
      confirmPassword: "",
      errorMsg1: "",
      errorMsg2: "",
    };
  },
  methods: {
    onBacktoLogin() {
      this.$router.push({ name: "account" });
    },
    onResetPassword() {
      if (!this.newPassword || !this.confirmPassword) {
        this.errorMsg1 = "The password field is required";
        this.errorMsg2 = "The password field is required";
      } else if (this.newPassword != this.confirmPassword) {
        this.$swal({
          title: "",
          text: "New password and confirm password are not matching",
          icon: "error",
        });
      } else {
        let payload = {
          token: this.resetPasswordToken,
          password: this.confirmPassword,
        };
        this.$store
          .dispatch("permitUserResetPassword", payload)
          .then((response) => {
            if (response.res == 0) {
              this.$swal({ title: "", text: response.msg, icon: "success" });
              this.onBacktoLogin();
            } else {
              this.$swal({ title: "", text: response.msg, icon: "error" });
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    },
  },
};
</script>