import Vue from 'vue'
import VueRouter from 'vue-router'

import Account from '../components/Login.vue'
import Layout from '../components/Layout.vue'
import PermitCheckTransaction from '../components/Pages/transaction.vue'
import PermitCheckUsers from '../components/Pages/user.vue'
import PermitCheckPricing from '../components/Pages/pricing.vue'
import History from  '../components/Pages/history.vue'
import ResetPassword from '../components/ResetPassword.vue'
// import ticket from '../components/support-ticket/ticket.vue'
import unarthorized from '../components/Unauthorized.vue'
import Invoices from '../components/Pages/invoices.vue'
import SendEmail from '../components/notifications/sendEmail.vue'

Vue.use(VueRouter)

function isLoggedIn(to, from, next) {
    const session = localStorage.getItem(btoa(btoa('permitAuthKey')));
    if (session !== undefined && session !== null) {
        let sessionData = localStorage.getItem(btoa(btoa("permitSession")));
        sessionData = atob(atob(sessionData)).toLowerCase();
        sessionData = JSON.parse(sessionData);
        if (sessionData && sessionData.user_id) {
            next()
        } else {
            next({ name: "account" });
        }
    } else {
        // delete below line
        // next();
        // uncomment below line
        next({ name: "account" });
    }
}

const routes = [
    {
        path: "/",
        name: "account",
        component: Account
    },
    {
        path: '/reset/:resetPasswordToken',
        name: 'reset',
        component: ResetPassword,
        props: true
      },
      {
        path: '/unarthorized',
        name: 'unarthorized',
        component: unarthorized,
      },
    {
        path: "/Layout",
        name: "layout",
        component: Layout,
        redirect: '/permit-check-transaction',
        beforeEnter: (to, from, next) => {
            // if(isLoggedIn(to, from, next)){
            //     next()
            // }else{
            //     next({ name: "unarthorized" });
            // }
            isLoggedIn(to, from, next);
        },
        children: [{
            path: "/permit-check-transaction",
            name: "permitCheckTransaction",
            component: PermitCheckTransaction,
            // beforeEnter: (to, from, next) => {
            //     if(isLoggedIn(to, from, next)){
            //         next()
            //     }else{
            //         next({ name: "unarthorized" });
            //     }
            // },
        },
        {
            path: "/permit-check-users",
            name: "permitCheckUsers",
            component: PermitCheckUsers,
        },
        {
            path: "/permit-check-pricing",
            name: "permitCheckPricing",
            component: PermitCheckPricing,
        },
        {
            path: "/permit-check-history",
            name: "history",
            component: History,
        },
        {
            path: "/permit-check-invoices",
            name: "permitCheckInvoices",
            component: Invoices,
        },
        // {
        //     path: '/support-ticket',
        //     name: 'ticket',
        //     component: ticket
        // },
        {
            path: '/send-email',
            name: 'sendEmail',
            component: SendEmail
        }
        ]
    },
]

const router = new VueRouter({
    routes
})

export default router