<template>
  <div class="full-container">
    <div class="transaction-view">
    <div class="panel-collapse-container">
      <div class="panel-collapse-header transaction-header">
        <div class="columns input-color-white input-search">
          <div class="column is-4">
            <div class="panel-collapse-header-text t2-pixel">
              <span class="t4-pixel">Buildchek Invoices</span>
            </div>
          </div>
          <div class="panel-collapse-actions header-action paddingright column margin-top-0">
            <div class="action-button pull-right margin-top-5">
              <md-button class="md-raised md-primary">
              <!-- <md-button class="md-raised md-primary" @click="newInvoice('AddInvoice')"> -->
                Generate New Invoice
              </md-button>
            </div>
            <div class="pull-right padding-left-10 transaction-header-dropdown width-200px">
              <md-input-container class="theme-white">
                <md-icon>
                  <magnify-icon />
                  <!-- <magnify-icon :title="tipsearch" /> -->
                </md-icon>
                <!-- <md-input v-model="search" @input="searchData" placeholder="Search" type="text"> </md-input> -->
                <md-input placeholder="Search" type="text"> </md-input>
                <span class="cursor-pointer">
                <!-- <span @click="clearSearch" class="cursor-pointer"> -->
                  <md-icon>
                    <close-icon />
                    <!-- <close-icon :title="tipclear" /> -->
                  </md-icon>
                </span>
              </md-input-container>
            </div>
          </div>
        </div>
      </div>
      <div class="panel-collapse-body">
        <div class="company-table overflow-auto font-size-14">
          <div class="no-records" v-if="1">
          <!-- <div class="no-records" v-if="$store.state.transaction.dataisLoading"> -->
            <img class="custom-loader m-r-10" width="2%" src="/src/assets/img/app-loader.svg" />
          </div>
          <div v-else-if="!$store.state.transaction.dataisLoading && invoiceList.length==0" class="no-records">
            No Hit Invoice Available.
          </div>
          <table class="table is-fullwidth">
          <!-- <table class="table is-fullwidth" v-show="!$store.state.transaction.dataisLoading && invoiceList.length>0"> -->
            <thead>
              <tr class="word-break">
                <th width="15%">Invoice Date</th>
                <th width="25%">Invoice To</th>
                <th width="10%">Invoice Link</th>
                <th width="25%">Description</th>
                <th width="9%">Status</th>
                <th width="8%">Amount</th>
                <th width="8%"></th>
              </tr>
            </thead>
            <tbody>
              <!-- <tr v-for="(data,index) in invoiceList" :key="data.hit_invoice_id">
                <td>{{data.invoice_date|FormatFullDateToMMDD}}</td>
                <td>{{data.invoice_to.replace(/,/g, " ") | returnDashIfNull}}</td>
                <td>
                  <span>
                    <a v-if="data.invoice_link" target="_blank" class="anchor-link" :href="data.invoice_link">View Invoice</a>
                  </span>
                </td>
                <td>
                  <read-more more-str="read more" :text="data.description" class="news-description" link="#" less-str="read less" :max-chars="120"> </read-more>
                </td>
                <td :class="{'success-color': data.is_paid==1 ,'fail-color' :!data.is_paid}">
                  <p class="is-inline-flex">
                    {{data.is_paid? 'Paid' : 'Not Paid'}}
                  </p>
                </td>
                <td><b></b>{{data.amount | toUSDCurrencyFormat}}</td>
                <td>
                  <div class="square-menu right" @click="DeleteHitInvoice(data.hit_invoice_id,'confirmdel')">
                    <a>
                      <delete-icon class="black" :title="tipDelete" />
                    </a>
                  </div>
                  <div class="square-menu right" @click="resendInvoice(data,'AddInvoice')">
                    <a>
                      <send-icon class="black" :title="tipResend" />
                    </a>
                  </div>
                </td>
              </tr> -->
            </tbody>
          </table>
        </div>
        <!-- <div v-show="!$store.state.transaction.dataisLoading && invoiceList.length" class="pagination-wrap text-center p-t-20">
          <paginate ref="paginate" :page-count="number_of_page" prev-text="Prev" next-text="Next" container-class="pagination" :click-handler="clickCallback" :initial-page="0"> </paginate>
          <div class="resultcountlabel is-marginless p-10 p-t-0">
            Total results {{totalCount}}
          </div>
        </div> -->
      </div>
    </div>

    <!-- add/update invoive start  -->
    <md-dialog ref="AddInvoice" :md-click-outside-to-close="false" :md-esc-to-close="false" class="dialog-width-60">

    <!-- <md-dialog ref="AddInvoice" :md-click-outside-to-close="false" :md-esc-to-close="false" class="dialog-width-60" @open="disablescrolling" @close="enablescrolling"> -->
      <div class="panel training-dialog">
        <md-dialog-title class="md-dialog-title md-title md-dialog-custom-title popup-action margin-1">
          <span>Generate Invoice</span>
          <div class="header-action">
            <div class="action-button pull-right">
              <md-button class="md-raised md-primary">
              <!-- <md-button class="md-raised md-primary" @click="closeInvoiceDialog('AddInvoice')"> -->
                CLOSE
              </md-button>
            </div>
            <div class="action-button pull-right">
              <md-button class="md-raised md-primary">
              <!-- <md-button class="md-raised md-primary" @click="saveInvoice('AddInvoice')"> -->
                Save
              </md-button>
            </div>
          </div>
        </md-dialog-title>
      </div>
      <md-dialog-content>
        <div class="width-100">
          <form>
            <div class="columns is-multiline">
              <div class="column is-6 pb0">
                <md-input-container :class="{ 'md-input-invalid': errors.has('to') }">
                <!-- <md-input-container :class="{ 'md-input-invalid': errors.has('to') }"> -->
                  <label class="black">TO</label>
                  <md-input class="black" tabindex="1" data-vv-name="to" data-vv-as="email to"> </md-input>

                  <!-- <md-input class="black" tabindex="1" v-model="invoice.to" data-vv-name="to" data-vv-as="email to" v-validate="{required:true,regex:/^(\s?[^\s,]+@[^\s,]+\.[^\s,]+\s?,)*(\s?[^\s,]+@[^\s,]+\.[^\s,]+)$/}"> </md-input> -->
                  <span class="md-error">Dummy data</span>
                  <!-- <span class="md-error">{{ errors.first('to') }}</span> -->
                </md-input-container>
              </div>
              <div class="column is-6 pb0">
                <md-input-container>
                  <!-- <md-input-container :class="{ 'md-input-invalid': errors.has('amount') }"> -->
                  <label class="black">Dummy data</label>
                  <!-- <label class="black">Amount($)</label> -->
                  <md-input class="black" tabindex="2" > </md-input>
                  <!-- <md-input class="black" tabindex="2" v-model="invoice.amount" data-vv-name="amount" v-mask="{regex:'^[+]?[0-9]+([.][0-9]{1,2})?$'}" v-validate="{required:true,regex:/^[+]?[0-9]+([.][0-9]{1,2})?$/}"> </md-input> -->
                  <span class="md-error">dummy data</span>
                  <!-- <span class="md-error">{{ errors.first('amount') }}</span> -->
                </md-input-container>
              </div>
              <!-- <div class="column is-12 padding-top-0 margin-top-0" v-if="IsOpenPopup">
                <label>Description</label>
                <ckeditor :class="{'border-danger': errors.has('description')}" data-vv-name="description" v-validate data-vv-rules="required" tabindex="3" :name="EditorId" :id="EditorId" v-model="invoice.description" :config="config">
                </ckeditor>
                <span class="md-error">{{errors.first('description')}}</span>
              </div> -->
            </div>
          </form>
        </div>
      </md-dialog-content>
    </md-dialog>
    <!--  add/update invoive end  -->
    <!-- delete popup  -->
    <md-dialog ref="confirmdel">
      <md-dialog-title>Are you sure you want to delete this Invoice?</md-dialog-title>
      <md-dialog-actions>
        <md-button class="md-primary">Cancel</md-button>
        <md-button class="md-primary">Ok</md-button>
        <!-- <md-button @click="closeDialog('confirmdel')" class="md-primary">Cancel</md-button>
        <md-button @click="okDialog('confirmdel')" class="md-primary">Ok</md-button> -->
      </md-dialog-actions>
    </md-dialog>
    </div>
  </div>
</template>
<script>
export default {
  name: "permit-check-invoices",
  // components:{checkboxMarked,checkboxBlankOutline},
  data() {
    return {
      search: ''
    }
  }
}
</script>