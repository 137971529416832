<template>
  <md-dialog class="dialog-width-100 campaigns" @open="disablescrolling" @close="enablescrolling"
    ref="addContactEmail">
      <md-dialog-title class="md-dialog-custom-title panel-collapse-header popup-action margin-bottom-0 margin-1">
        <div class="columns input-color-white input-search">
          <div class="column">
            Add Emails
          </div>
          <div class="action-button pull-right margin-top-0" v-show="!showSearch"
              v-if='typeof permitUserList != "undefined"  && permitUserList.length > 0  && search=="" && !allUserSelected ' @click="showSearchInput">
              <md-button class="md-raised md-primary">
                Search
              </md-button>
            </div>
          <div class="column input-search-icon" v-show="showSearch">
            <div class="t2-pixel">
              <md-input-container class="theme-white">
                <md-icon>
                  <magnify-icon title="Search" />
                </md-icon>
                <md-input placeholder="Search Contacts" v-model.trim="search" type="text" @input="searchData">
                </md-input>
                <span class="md-icon md-theme-white material-icons" @click.prevent="clearSearch">
                  <md-icon>
                    <close-icon title="Cancel" />
                  </md-icon>
                </span>
              </md-input-container>
            </div>
          </div>
          <div class="action-button pull-right margin-top-0">
            <md-button @click="closeDialog('addContactEmail')" class="md-raised md-primary">
              Close
            </md-button>
          </div>
        </div>
      </md-dialog-title>
      <div class="tab-body-ui border-grey">
        <div class="columns margin0">
           <div class="column is-6 padding-top-bottom-0">
            <md-checkbox  class="md-primary compact-radio" style="margin-left: 20px;"
              md-value="1" v-model="allUserSelected" @change="selecteAllUsers($event)"> Select All Users
            </md-checkbox>
          </div>
        </div>
      </div>
      <div v-if="!allUserSelected">
        <div class="tags-container">
          <div v-for="(user, index) in selectedContactsEmail" :key="index" class="tag">
            {{ user.first_name }} {{ user.last_name }}
            <span class="cross-icon" @click="removeUser(index)">&#x2716;</span>
          </div>
        </div>
        <hr>
      </div>
      <div v-else class="notification is-success has-text-centered">
        You have selected all users!
      </div>
      <!-- USERS OLD LOGIC --> 
      <md-table class="width-100">
        <md-table-header v-if="permitUserList.length">
          <md-table-row>
            <md-table-head v-if="!allUserSelected"></md-table-head>
            <md-table-head>Name</md-table-head>
            <md-table-head md-numeric>Email Address </md-table-head>
            <!-- <md-table-head md-numeric>Phone Number</md-table-head>
            <md-table-head md-numeric>Created Date</md-table-head>
            <md-table-head md-numeric>Registered from</md-table-head>
            <md-table-head md-numeric>Total Buildchek</md-table-head>
            <md-table-head md-numeric>Contact Page</md-table-head>
            <md-table-head></md-table-head>
            <md-table-head></md-table-head> -->
          </md-table-row>
        </md-table-header>
        
        <md-table-body v-if="permitUserList.length">
          <md-table-row v-for="(row, index) in permitUserList" :key="row.user_id">
            <md-table-cell v-if="!allUserSelected">
              <md-checkbox name="checkbox" @change="OnCheckContactForAdd(row,index)" class="md-primary margin0"
                v-model="row.selected"></md-checkbox>
            </md-table-cell>
            <md-table-cell>
              {{ row.first_name + " " + row.last_name }}
            </md-table-cell>

            <md-table-cell>
              {{ row.email_address ? row.email_address : "-" }}
            </md-table-cell>

            <!-- <md-table-cell>
              {{ row.phone_number | FormatPhoneNo }}
            </md-table-cell>

            <md-table-cell>
              {{ row.created_date | FormatDateToMMDD }}
            </md-table-cell>

            <md-table-cell>
              <span
                v-if="row.device_type && row.device_type.toLowerCase() == 'w'"
              >
                Web
              </span>
              <span
                v-else-if="
                  row.device_type && row.device_type.toLowerCase() == 'a'
                "
              >
                Android
              </span>
              <span
                v-else-if="
                  row.device_type && row.device_type.toLowerCase() == 'i'
                "
              >
                iOS
              </span>
              <span v-else> - </span>
            </md-table-cell>

            <md-table-cell>
              <span class="p-l-30">
                {{ row.total_permits }}
              </span>
            </md-table-cell>

            <md-table-cell>
              <span>
                <a
                  style="color: #3c3c3c; cursor: pointer;text-decoration:none"
                  v-if="row.email_address"
                  @click="viewContactpages(row)"
                  class="font-size-14 display-block"
                >
                  <b>View contact pages</b>
                </a>
              </span>
            </md-table-cell>

            <md-table-cell>
              <span>
                <a @click="viewLoginDetails(row)">
                  <i
                    style="color: #3c3c3c; top: 15px"
                    class="fa-solid fa-circle-info fa-fa-icon cursor-pointer"
                  >
                    <md-tooltip style="background-color: black;"
                      md-direction="top"
                      class="common-tooltip multi-line"
                      >View Login Details
                    </md-tooltip>
                  </i>
                </a>
              </span>
            </md-table-cell>
            <md-table-cell>
              <a @click="sendCredsOnMail(row)">
                <i
                  style="color: #3c3c3c; top: 15px;"
                  class="fa-solid fa-envelope fa-fa-icon cursor-pointer">
                </i>
                <md-tooltip style="background-color: black;"
                    md-direction="top"
                    class="common-tooltip multi-line"
                    >Send credentials on email
                  </md-tooltip>
              </a>
            </md-table-cell> -->
            <!-- <md-table-cell>
              <md-menu :md-size="4" class="right">
                <i
                  class="fa fa-ellipsis-v cursor-pointer"
                  md-menu-trigger
                  style="font-size: 19px"
                ></i>
                <md-menu-content> -->
                  <!-- // need to comment below md-menu-item -->
                  <!-- <md-menu-item @selected="enableAccess(row)">
                    {{
                      row.enable_api_access == 0
                        ? "Enable API Access"
                        : "Disable API Access"
                    }}
                  </md-menu-item> -->
                  <!-- <md-menu-item
                    @selected="
                      openDialog('add-permitchek-file-upload', row.user_id)
                    "
                  >
                    Upload File
                  </md-menu-item>
                  <md-menu-item @selected="ReportFileDetails(row.user_id)">
                    View File
                  </md-menu-item>
                </md-menu-content>
              </md-menu>
            </md-table-cell> -->
          </md-table-row>
        </md-table-body>

        <md-table-body v-if="permitUserList.length == 0">
          <md-table-cell class="no-data"
            >No Buildchek users available.</md-table-cell
          >
        </md-table-body>
      </md-table>
      <md-dialog-actions>
        <!-- <span style="color: red !important;font-size:14px;margin-right: 15px;">(Maximum you can select 50 recipients) </span> -->
      <div class="button-group">
        <button type="button" @click="addSelectedUsers()"
          class="button btn btn-add">
           {{ allUserSelected ? 'Add All Users' : 'Add Selected Contacts'}}</button>
        <button type="button" class="button btn btn-cancel" @click="closeDialog('addContactEmail')">Cancel</button>
      </div>
    </md-dialog-actions>
      <div
        class="text-align-center"
        v-show="permitUserList.length"
      >
        <paginate
          ref="refPagintationUser"
          :page-count="numberOfPage"
          :prev-text="prev"
          :next-text="next"
          :container-class="pagination"
          :click-handler="clickCallback"
          :initial-page="0"
        >
        </paginate>
      </div>
    </md-dialog>
</template>

<script>
// import VueTagsInput from '@johmun/vue-tags-input';
import Paginate from "vuejs-paginate";
export default {
  name: 'UserModal2',
  components: {
    // VueTagsInput
    Paginate
  },
  props: {
    allSelected: {
      type: Boolean,
      required: true
    },
    selectedUsers: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      recordsPerPage: 10,
      numberOfPage: 0,
      pageNo: 1,
      search: '',
      start_date: "",
      end_date: "",
      permitStartDate: "",
      permitEndDate: "",
      searchByApiType: '',
      showSearch: false,
      selectedContactsEmail: [],
      prev: "Prev",
      next: "Next",
      pagination: "pagination",
      allUserSelected: false
    }
  },
  // watch: {
  //   allSelected(newValue) {
  //     this.allUserSelected = newValue;
  //   },
  // },
  computed: {
    permitUserList: function () {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.numberOfPage = Math.ceil(
        this.$store.state.total_users / this.recordsPerPage
      );
      let usersList = this.$store.state.permitUserList.map((x) => {
        x.property_address = "";
        if (x.inspection && x.inspection.property) {
          let property = x.inspection.property;
          let stateName =
            property && property.state && property.state.state_name
              ? property.state.state_name
              : "";
          let countryName =
            property && property.country && property.country.country_name
              ? property.country.country_name
              : "";
          x.property_address = this.generateAddress(
            this.generateAddressLine(
              property.address_line1,
              property.address_line2
            ),
            property.city,
            this.generateAddressLine(stateName, property.zip_code),
            countryName
          );
        }
        return x;
      });


      let modifiedUsersList;
      console.log(this.allUserSelected)
      if(this.allUserSelected) {
        modifiedUsersList = usersList.map(user => {
          return {
            ...user,
            selected: true
          }
        })
        // if(this.pageNo != 1) {
          // this.selectedContactsEmail = [...this.selectedContactsEmail, ...modifiedUsersList];
        // }
      } else {

        modifiedUsersList = usersList.map(user => {
          const isSelected = this.selectedContactsEmail.some(selectedUser => selectedUser.user_id === user.user_id);
          return {
            ...user,
            selected: isSelected
          };
        });
      }
      return modifiedUsersList;
    },
  },
  methods : {
    fetchUsers: function() {
        let payload = {
        PageNo: this.pageNo,
        PageRecord: this.recordsPerPage,
        search: this.search,
        start_date: this.start_date,
        end_date: this.end_date,
        permit_type: this.searchByApiType,
        permit_start_date:this.permitStartDate,
        permit_end_date:this.permitEndDate,
      };
      this.$store
        .dispatch("permitUsersList", {
          payload,
        })
        .then((response) => {
          if (response && response.res == 1) {
            this.openGlobalSnackbar(response.msg);
          }
      });
    },
    disablescrolling() {
      this.allUserSelected = this.allSelected;
      console.log(this.selectedUsers);
      console.log(this.selectedContactsEmail);
      this.selectedContactsEmail =  [ ... this.selectedUsers ];
      this.fetchUsers();
    },
    showSearchInput() {
      this.showSearch = true;
    },
    searchData: function () {
      this.fetchUsers()
    },
    closeDialog() {
      if(this.selectedContactsEmail.length == 0) {
        this.selectedContactsEmail = [];
      }
     
      // this.allUserSelected = false;
      this.$refs['addContactEmail'].close();
    },
    removeUserFromList(tag) {
      const userIdToRemove = tag.tag.user_id;
      this.selectedContactsEmail = this.selectedContactsEmail.filter(contact => contact.user_id !== userIdToRemove);
    },
    clearSearch: function () {
      this.search = "";
      this.showSearch = false;
      this.fetchUsers();
      // this.$refs['addContactEmail'].close();
    },
    OnCheckContactForAdd(contact, index) { 
      console.log(contact, index);
      console.log(this.selectedContactsEmail);

      if(this.allUserSelected) {
        this.allUserSelected = false;
      }
      // Find the index of the contact in the selectedContactsEmail array
      const userIdIndex = this.selectedContactsEmail.findIndex((el) => el.user_id === contact.user_id);

      if (userIdIndex !== -1) {
        // If the contact is found, remove it from the array
        this.selectedContactsEmail.splice(userIdIndex, 1);
      } else {
        // If the contact is not found, add it to the array
        this.selectedContactsEmail.push(contact);
      }
    },
    clickCallback: function (PageNo) {
      this.pageNo = PageNo;
      this.fetchUsers()
    },
    removeUser(index) {
      console.log(index);
      console.log(this.selectedContactsEmail)
      this.selectedContactsEmail.splice(index, 1);
    },
    addSelectedUsers() {
      if(this.selectedContactsEmail.length == 0 && !this.allUserSelected) {
        this.openGlobalSnackbar('Please select atleast one user!');
        return;
      }
      console.log(this.allUserSelected)
      // this.$emit('selectedUsers', cloneDeep(this.selectedContactsEmail));
      const data = this.allUserSelected ? 1 : this.selectedContactsEmail;
      this.$emit('selectedUsers', data);
      this.$refs['addContactEmail'].close();

      // this.selectedContactsEmail = [];
    },
    selecteAllUsers(value) {
      if(!value) {
        this.selectedContactsEmail = [];
        return;
      }
      if(this.search) {
        this.clearSearch();
      }
      // this.clearSearch();
      // this.selectedContactsEmail = [...this.permitUserList];
    },
    clearData() {
      this.selectedContactsEmail = [];
    },
    enablescrolling() {
      // this.allUserSelected = false;
    }
  }
 }

</script>

<style scoped>
  .tags-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 10px;
    margin-left: 21px;
  }

  .tag {
    display: flex;
    align-items: center;
    background-color: #e0e0e0;
    border-radius: 4px;
    padding: 5px 10px;
    font-size: 14px;
  }

  .cross-icon {
    margin-left: 10px;
    cursor: pointer;
    color: #ff0000;
  }

  .btn {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  margin: 5px;
}

/* Add button specific styles */
.btn-add {
  background-color: #28a745;
  color: white;
}

.btn-add:hover {
  background-color: #218838;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.btn-add:active {
  background-color: #1e7e34;
}

/* Cancel button specific styles */
.btn-cancel {
  background-color: #dc3545;
  color: white;
}

.btn-cancel:hover {
  background-color: #c82333;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.btn-cancel:active {
  background-color: #bd2130;
}

.notification {
  padding: 1rem;
  border-radius: 5px;
  margin-top: 1rem;
  background-color: #f0f0f0; /* Light grey background */
  color: #363636; /* Dark text */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}

.has-text-centered {
  text-align: center;
}

.notification.is-success {
  background-color: #d4edda; /* Light green background for success */
  color: #155724; /* Dark green text for success */
}

</style>