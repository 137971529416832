import Vue from 'vue';
import 'vue-material-design-icons/styles.css';

import mangnifyIcon from 'vue-material-design-icons/magnify.vue';
import Close from 'vue-material-design-icons/close.vue';
import infoIcon from 'vue-material-design-icons/information-outline.vue';
import checkboxmarked from './checkbox-marked.vue';
import checkboxblankoutline from './checkbox-blank-outline.vue';
import Delete from 'vue-material-design-icons/delete.vue';

Vue.component('delete-icon', Delete);
Vue.component('checkbox-marked', checkboxmarked);
Vue.component('checkbox-blank-outline', checkboxblankoutline);
Vue.component('magnify-icon', mangnifyIcon);
Vue.component('close-icon', Close)
Vue.component('info-icon', infoIcon);
