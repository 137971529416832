<template>
  <div class="material-design-icon" id="checkbox-blank-outline-icon" role="img" aria-labelledby="checkbox-blank-outline-icon-title">
    <svg class="material-design-icon__svg" width="24" height="24" viewBox="0 0 24 24">
      <path d="M19,3H5C3.89,3 3,3.89 3,5V19C3,20.1 3.9,21 5,21H19C20.1,21 21,20.1 21,19V5C21,3.89 20.1,3 19,3M19,5V19H5V5H19Z"></path>
    </svg>
    <md-tooltip style="background-color: black;" md-direction="bottom" class="common-tooltip">{{iconTitle}}</md-tooltip>
  </div>
</template>

<script>
export default {
  name: "checkbox-blank-outline-icon",
  data() {
    let iconTitle = this.title ? this.title : "Checkbox blank outline icon";

    return {
      iconTitle: iconTitle
    };
  },
  props: {
    title: {
      type: String
    }
  }
};
</script>
