
export function disablescrolling() {
    let body = document.body;
    body.style.overflow = 'hidden'
}
export function enablescrolling() {
    let body = document.body;
    body.style.overflow = ''
}
export function GlobalUserID() {
    let data = localStorage.getItem(btoa(btoa("permitSession")));
    data = atob(atob(data)).toLowerCase();
    data = JSON.parse(data);
    return data.user_id ? data.user_id : 0;
}
export function show() {
    
    document.getElementById('app-loader').style.display = 'block';
}
export function hide() {
    document.getElementById('app-loader').style.display = 'none';
}
