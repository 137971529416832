import Vue from "vue";
import Vuex from 'vuex'
import api from './../api/index'
import axiosutil from "@/api/AxiosCofig";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    permitTransactionListing: [],
    total_permitTransaction: 0,
    total_transactionAmount: 0,
    total_fail: 0,
    total_refund: 0,
    permitHistoryLogs: [],
    total_logs: 0,
    permitUserList: [],
    total_users: 0,
    total_permit_pulls: 0,
    total_cloud_pulls: 0,
    //pricing
    subscriptionPlanList: [],
    internachiSubscriptionList: [],
    is_loading: false,
    is_active: 1,
    paymentservices: [],
    is_config_quickbooks: false,
    isLoading: {
      payment: true,
      savedCards: true,
    },
  },
  actions: {
    fetchIPAddress: (context, url) =>
      new Promise((resolve, reject) => {
        api.fetchIPAddress(url)
          .then(function (response) {
            resolve(response);
          })
          .catch(function (error) {
            console.log(error);
            reject(error);
          });
      }),
    userLogin: function (context, payload) {
      return api.userLogin(payload)
        .then(function (response) {
          if (response.res == '0') {
            axiosutil.setSession(response);
          }
          return response;
        }, function (error) {
          console.log(error);
        })
    },
    permitUserLogout: function (context, payload) {
      return api.permitUserLogout(payload)
        .then(function (response) {
          if (response.res == '0') {
            axiosutil.clearSession()
          }
          return response;
        }, function (error) {
          console.log(error);
        })
    },
    permitUserForgotPassword: function (context, payload) {
      return api.permitUserForgotPassword(payload)
        .then(function (response) {
          return response;
        }, function (error) {
          console.log(error);
        })
    },
    permitUserResetPassword: function (context, payload) {
      return api.permitUserResetPassword(payload)
        .then(function (response) {
          return response
        }, function (error) {
          console.log(error);
        })
    },
    permitCheckPrizerList: function (context, { payload }) {
      return api.permitCheckPrizerList(payload)
        .then(function (response) {
          if (response.res == '0') {
            context.commit('PERMIT_TRANSACTION_LIST', response);
          }
          return response;
        }, function (error) {
          console.log(error);
        })
    },
    permitHistoryLogs: function (context, { payload }) {
      return api.permitHistoryLogs(payload).then(function (response) {
        if (response.res == 0) {
          context.commit('PERMIT_HISTORY_LOGS', response);
        }
        return response;
      }, function (reject) {
        console.log('permitHistoryLogs: ', reject);
      });
    },
    permitUsersList: function (context, { payload }) {
      return api.permitUsersList(payload).then(function (response) {
        if (response.res == 0) {
          context.commit('PERMIT_USERS_LIST', response);
        }
        return response;
      }, function (reject) {
        console.log('permitUsersList: ', reject);
      });
    },
    sendEmail: function (context, payload ) {
      return api.sendEmail(payload).then(function (response) {
        return response;
      }, function (error) {
        console.log(error);
      });
    },
    permitUserDisable: function (context,  payload ) {
      return api.permitUserDisable(payload).then(function (response) {
       
        return response;
      }, function (error) {
        console.log(error);
      });
    },
    permitUserActivate: function (context,  payload ) {
      return api.permitUserActivate(payload).then(function (response) {
        return response;
      }, function (error) {
        console.log(error);
      });
    },
    permitUserDelete: function (context,  payload ) {
      return api.permitUserDelete(payload).then(function (response) {
       
        return response;
      }, function (error) {
        console.log(error);
      });
    },
    sendCredentialsOnEmail: function(context, payload) {
      return api.sendCredentialsOnEmail(payload).then(function (response) {
       
        return response;
      }, function (error) {
        console.log(error);
      });
    },
    updateInterNachiUser: function(context, payload) {
      return api.updateInterNachiUser(payload).then(function (response) {
       
        return response;
      }, function (error) {
        console.log(error);
      });
    },
    ReportFileDetails: function (context, payload) {
      return api.ReportFileDetails(payload).then(function (response) {
        return response;
      }, function (reject) {
        console.log('ReportFileDetails: ', reject);
      });
    },
    permitUserRegistration: function (context, payload) {
      return api.permitUserRegistration(payload)
        .then(function (response) {
          return response;
        }, function (reject) {
          console.log('permitUserRegistration: ', reject);
        })
    },
    GenerateReportBulk: function (context, payload) {
      return api.GenerateReportBulk(payload)
        .then(function (response) {
          return response;
        }, function (reject) {
          console.log('GenerateReportBulk: ', reject);
        })
    },
    getAndEditPermitchekPrice() {
      return api.getAndEditPermitchekPrice()
        .then(function (response) {
          return response;
        }, function (reject) {
          console.log('getAndEditPermitchekPrice: ', reject);
        });
    },
    updatePermitCheckPrice: function (context, payload) {
      return api.updatePermitCheckPrice(payload)
        .then(function (response) {
          return response;
        }, function (reject) {
          console.log('updatePermitCheckPrice: ', reject);
        });
    },
    getPermitSubscriptions: function () {
      return api.getPermitSubscriptions()
        .then(function (response) {
          return response;
        }, function (reject) {
          console.log('getPermitSubscriptions: ', reject);
        });
    },
    deletePermitSubscription: function (context, SubscriptionId) {
      return api.deletePermitSubscription(SubscriptionId)
        .then(function (response) {
          return response;
        }, function (reject) {
          console.log('deletePermitSubscription: ', reject);
        });
    },
    addUpdatePermitSubscription: function (context, { payload }) {
      return api.addUpdatePermitSubscription(payload)
        .then(function (response) {
          return response;
        }, function (reject) {
          console.log('addUpdatePermitSubscription: ', reject);
        });
    },
    changePermitSubscriptionStatus: function (context, payload) {
      return api.changePermitSubscriptionStatus(payload)
        .then(function (response) {
          return response;
        }, function (reject) {
          console.log('changePermitSubscriptionStatus: ', reject);
        });
    },
    // pricing
    getpaymentservicetypes: function (context, payload) {
      return api.getpaymentservicetypes(payload)
        .then(function (response) {
          return response;
        }, function (reject) {
          console.log('getpaymentservicetypes: ', reject);
        });
    },
    getPermitPaymentService: function () {
      return api.getPermitPaymentService()
        .then(function (response) {
          return response;
        }, function (reject) {
          console.log('getPermitPaymentService: ', reject);
        });
    },
    validatesignaturecardmerchant: (context, payload) =>
      new Promise((resolve, reject) => {
        api.validatesignaturecardmerchant(payload)
          .then(function (response) {
            resolve(response);
          })
          .catch(function (error) {
            reject(error)
          });
      }),

    validateauthorizenetservice: (context, obj) =>
      new Promise((resolve, reject) => {
        api.validateauthorizenetservice(obj)
          .then(function (response) {
            resolve(response);
          })
          .catch(function (error) {
            reject(error)
          });
      }),
    ValidateFluidPay(context, payload) {  
        return api.validatefluidpay(payload).then((response)=>{
          return response;
        })
    },
    permitPaymentService: function (context, { data }) {
      return api.permitPaymentService(data)
        .then(function (response) {
            return response;
        }, function (reject) {
          console.log('permitPaymentService: ', reject);
        });
    },
    permitDefaultPaymentService: function (context, { company_payment_service_id }) {
      return api.permitDefaultPaymentService(company_payment_service_id)
        .then(function (response) {
            return response;
        }, function (reject) {
          console.log('permitDefaultPaymentService: ', reject);
        });
    },
  },
  mutations: {
    PERMIT_TRANSACTION_LIST: function (state, response) {
      state.permitTransactionListing = response.data.rows && response.data.rows.length ? response.data.rows : []
      state.total_permitTransaction = response.data.count ? response.data.count : 0;
      state.total_transactionAmount = response.total_amount ? response.total_amount : 0;
      state.total_fail = response.total_fail ? response.total_fail : 0;
      state.total_refund = response.total_refund ? response.total_refund : 0;
    },
    PERMIT_HISTORY_LOGS: function (state, response) {
      state.permitHistoryLogs = response.data.rows && response.data.rows.length ? response.data.rows : []
      state.total_logs = response.data.count ? response.data.count : 0;
    },
    PERMIT_USERS_LIST: function (state, response) {
      state.total_cloud_pulls = response.total_cloud_permit_count;
      state.total_permit_pulls = response.total_permit_count;
      state.permitUserList = response.data.rows && response.data.rows.length ? response.data.rows : []
      state.total_users = response.data.count ? response.data.count : 0;
    },
    ADD_UPDATE_SUBSCRIPTION: function (state, response) {
      console.log(response);
      state.subscriptionPlanList = response.data;
    },
    ADD_UPDATE_INTERNACHI_SUBSCRIPTION: function(state, response) {
      state.internachiSubscriptionList = response.data;
    },
    GET_SUBSCRIPTION_LIST: function (state, response) {
      console.log(response);
      state.subscriptionPlanList = response.data;
    },
    GET_INTERNACHI_SUBSCRIPTION_LIST: function (state, response) {
      state.internachiSubscriptionList = response.data;
    },
    DELETE_SUBSCRIPTION: function (state, response) {
      let obj = state.subscriptionPlanList.findIndex(x => x.subscription_id == response.SubscriptionId);
      let plan = null;
      if (obj != -1) {
        state.subscriptionPlanList.splice(obj, 1);
      } else {
        plan = state.internachiSubscriptionList.findIndex(x => x.subscription_id == response.SubscriptionId);
      }
      if(plan != -1) {
        state.internachiSubscriptionList.splice(obj, 1);
      }
    },
    CHANGE_SUBSCRIPTION_STATUS: function (state, response) {
      let obj = state.subscriptionPlanList.find(x => x.subscription_id == response.SubscriptionId);
      if (obj) {
        obj.is_active = response.is_active ? 1 : 0;
      } else {
        let internachiPlanStatus = state.internachiSubscriptionList.find(x => x.subscription_id == response.SubscriptionId);
        if(internachiPlanStatus) {
          internachiPlanStatus.is_active = response.is_active ? 1 : 0;
        }
      }
    },
    // payment
    START_LOADING: (state, res) => { state.isLoading[res] = true },
    STOP_LOADING: (state, res) => { state.isLoading[res] = false },
    ADD_NEW_PAYMENT_SERVICE: function (state, obj) {
      state.paymentservices.splice(0, 0, obj);
    },
    PAYMENT_SERVICES: function (state, response) {
      state.paymentservices = response.data;
      state.is_config_quickbooks = response.is_config_quickbooks;
    },
  },
  modules: {

  }
})