import eventBus from '../common-plugin/eventbus';
const Common = {
  install(Vue) {
    Vue.mixin({
      data() {
        return {
          durations: 2500
        }
      },
      methods: {
        openGlobalSnackbar(msg, duration = 2500, position = "top right") {
          eventBus.$emit('openGlobalSnackbar', { msg, duration, position })
        },

        disablescrolling: function () {
          // eslint-disable-next-line no-undef
          $("#html").addClass('model-open');
        },

        enablescrolling: function (is_from_main_schedule) {
          if (!is_from_main_schedule) {
            // eslint-disable-next-line no-undef
            $("#html").removeClass("model-open");
          }
        },
        symchar(word) {
          return (!word) ? '' : word[0] + word[1];
        }
      }
    })
  }

}

export default Common;
