import Vue from 'vue';
import moment from 'moment'

Vue.filter('FormatDateToMMDD', function (value) {
  if (!value) return value;
  return moment(value).format('MM/DD/YYYY');
});

Vue.filter('DateFormate', function(value){
  if (!value) return value;
  return moment(value).format("MM/DD/YYYY hh:mmA")

})

Vue.filter('FormatFullDateToMMDD', function (value) {
  if (!value) return value;
  return moment(value).format('MM/DD/YYYY hh:mm a');
});

Vue.filter('ToUSDCurrencyFormat', function(number){
  if (!number) {
    return '$0';
  }
  try {
    let dollarUS = Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    });
    return dollarUS.format(number);
  } catch (e) {
    return number;
  }
})

Vue.filter("FormatPhoneNo", function (value) {
  try {
    if (!value) return '';
    else {
      //Filter only numbers from the input
      let cleaned = ('' + value).replace(/\D/g, '');

      //Check if the input is of correct length
      let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

      if (match) {
        return '(' + match[1] + ') ' + match[2] + '-' + match[3]
      }
      else {
        return value
      }
    }
  } catch (e) {
    return value
  }
});