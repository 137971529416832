<template>
  <div id="app">
    <router-view />
      <md-snackbar
        :md-position="position"
        ref="snackbar"
        :md-duration="durations"
      >
      <span style="font-size: 130%">{{ snackBarMessage }}</span>
    </md-snackbar>
  </div>
</template>

<script>
import eventBus from "./common-plugin/eventbus"; 
export default {
  name: "App",
  components: {},
  data(){
    return{
      durations: 2500,
      position: "top right",
      snackBarMessage: "",
    }
  },
  mounted() {
    eventBus.$on("openGlobalSnackbar", this.openGlobalSnackbar);
  },
  methods: {
    openGlobalSnackbar({ msg, duration, position }) {
      this.position = position;
      this.durations = duration;
      this.snackBarMessage = msg;
      this.$refs.snackbar.open();
    },
  },
  beforeDestroy() {
    eventBus.$off("openGlobalSnackbar");
  },
};
</script>

<style>
@import "./assets/css/common.css";
@import "../src/assets/css/style.css";
@import "./../node_modules/vue-material/dist/vue-material.css";
@import "../src/assets/css/daterangepicker.css";
@import '../src/assets/css/ticket.css'
</style>
