<template>
  <div class="full-container">
    <div class="full-container-panel">
      <div class="columns transactions-header width-100">
        <div class="column is-2 inline-block panel-collapse-header-text width-20">
          <span class="t4-pixel">Buildchek Users</span>
        </div>
        <div class="column is-10 float-right width-80 penal-hd">
          <div class="columns inline-flex transaction-filter" style="align-items: center">
            <div class="column is-3 m-r">
              <div class="width-210 line-white filter-type-after">
                <md-input-container>
                  <label class="color-white">Filter by Type </label>
                  <md-select
                    placeholder="Filter by permit API"
                    name="company"
                    v-model="searchByApiType"
                    class="choices p-t-5"
                    @change="searchData"
                  >
                    <md-option value="" selected>No filter</md-option>
                    <md-option value="regular">Regular</md-option>
                    <md-option value="cloud">Cloud</md-option>
                  </md-select>
                </md-input-container>
              </div>
            </div>
            <div class="column is-3 m-r datepicker placeholderColor line-white width-210 input-focus">
              <md-input-container class="theme-white" style="min-height: 0px !important;padding-top: 6px !important;">
                  <DateRange :startDate="start" :endDate="end" label="Click here to select a date"
                    class="md-select choices md-theme-default custom_datepicker" @update-date="updateDateFilter"
                    @clear-date="clearDate" dateFormat="MM-DD-YYYY" key="date-filter">
                  </DateRange>
                </md-input-container>
            </div>
            <div class="column is-3 m-r">
              <div class="width-210 line-white search-color-input input-focus">
                <md-input-container class="theme-white" style="min-height: 0px !important;padding-top: 6px !important;">
                  <md-icon>
                    <magnify-icon title="Search" />
                  </md-icon>
                  <md-input
                    placeholder="Search Request"
                    v-model.trim="search"
                    @input="searchData"
                    type="text"
                  >
                  </md-input>
                  <span
                    class="md-icon md-theme-white material-icons searchbox"
                    @click="clearSearch"
                  >
                    <md-icon>
                      <close-icon :title="tipclear" />
                    </md-icon>
                  </span>
                </md-input-container>
              </div>
            </div>

            <!-- Date Range to filter pulled permit records -->
            <div class="column is-3 m-r datepicker placeholderColor line-white width-210 input-focus">
              <md-input-container class="theme-white" style="min-height: 0px !important;padding-top: 6px !important;">
                  <DateRange :startDate="filterStart" :endDate="filterEnd" label="Filter Permit Pulls"
                    class="md-select choices md-theme-default custom_datepicker" @update-date="updatePermitRangeDate"
                    @clear-date="clearPermitReportFilter" dateFormat="MM-DD-YYYY" key="permit-range-date">
                  </DateRange>
                </md-input-container>
            </div>
            <div class="column is-3">
              <div
                class="action-button pull-right"
                @click="openDialog('add-permitchek-user')"
              >
                <md-button class="md-raised md-primary">
                  ADD Buildchek User
                </md-button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <md-table class="width-100">
        <md-table-header v-if="permitUserList.length">
          <md-table-row>
            <md-table-head>Name</md-table-head>
            <md-table-head md-numeric>Email Address </md-table-head>
            <md-table-head md-numeric>Phone Number</md-table-head>
            <md-table-head md-numeric>Created Date</md-table-head>
            <md-table-head md-numeric>Registered from</md-table-head>
            <md-table-head md-numeric>Total Buildchek</md-table-head>
            <md-table-head md-numeric>Contact Page</md-table-head>
            <md-table-head></md-table-head>
            <md-table-head></md-table-head>
          </md-table-row>
        </md-table-header>

        <md-table-body v-if="permitUserList.length">
          <md-table-row v-for="row in permitUserList" :key="row.user_id">
            <md-table-cell>
              {{ row.first_name + " " + row.last_name }}
            </md-table-cell>

            <md-table-cell>
              {{ row.email_address ? row.email_address : "-" }}
            </md-table-cell>

            <md-table-cell>
              {{ row.phone_number | FormatPhoneNo }}
            </md-table-cell>

            <md-table-cell>
              {{ row.created_date | FormatDateToMMDD }}
            </md-table-cell>

            <md-table-cell>
              <span
                v-if="row.device_type && row.device_type.toLowerCase() == 'w'"
              >
                Web
              </span>
              <span
                v-else-if="
                  row.device_type && row.device_type.toLowerCase() == 'a'
                "
              >
                Android
              </span>
              <span
                v-else-if="
                  row.device_type && row.device_type.toLowerCase() == 'i'
                "
              >
                iOS
              </span>
              <span v-else> - </span>
            </md-table-cell>

            <md-table-cell>
              <span class="p-l-30">
                {{ row.total_permits }}
              </span>
            </md-table-cell>

            <md-table-cell>
              <span>
                <a
                  style="color: #3c3c3c; cursor: pointer;text-decoration:none"
                  v-if="row.email_address"
                  @click="viewContactpages(row)"
                  class="font-size-14 display-block"
                >
                  <b>View contact pages</b>
                </a>
              </span>
            </md-table-cell>

            <md-table-cell>
              <span>
                <a @click="viewLoginDetails(row)">
                  <i
                    style="color: #3c3c3c; top: 15px"
                    class="fa-solid fa-circle-info fa-fa-icon cursor-pointer"
                  >
                    <md-tooltip style="background-color: black;"
                      md-direction="top"
                      class="common-tooltip multi-line"
                      >View Login Details
                    </md-tooltip>
                  </i>
                </a>
              </span>
            </md-table-cell>
            <md-table-cell>
              <a @click="sendCredsOnMail(row)">
                <i
                  style="color: #3c3c3c; top: 15px;"
                  class="fa-solid fa-envelope fa-fa-icon cursor-pointer">
                </i>
                <md-tooltip style="background-color: black;"
                    md-direction="top"
                    class="common-tooltip multi-line"
                    >Send credentials on email
                  </md-tooltip>
              </a>
            </md-table-cell>
            <!-- <md-table-cell>
              <md-menu :md-size="4" class="right">
                <i
                  class="fa fa-ellipsis-v cursor-pointer"
                  md-menu-trigger
                  style="font-size: 19px"
                ></i>
                <md-menu-content> -->
                  <!-- // need to comment below md-menu-item -->
                  <!-- <md-menu-item @selected="enableAccess(row)">
                    {{
                      row.enable_api_access == 0
                        ? "Enable API Access"
                        : "Disable API Access"
                    }}
                  </md-menu-item> -->
                  <!-- <md-menu-item
                    @selected="
                      openDialog('add-permitchek-file-upload', row.user_id)
                    "
                  >
                    Upload File
                  </md-menu-item>
                  <md-menu-item @selected="ReportFileDetails(row.user_id)">
                    View File
                  </md-menu-item>
                </md-menu-content>
              </md-menu>
            </md-table-cell> -->
          </md-table-row>
        </md-table-body>

        <md-table-body v-if="permitUserList.length == 0">
          <md-table-cell class="no-data"
            >No Buildchek users available.</md-table-cell
          >
        </md-table-body>
      </md-table>

      <div
        class="text-align-center"
        v-show="permitUserList.length"
      >
        <paginate
          ref="refPagintationUser"
          :page-count="numberOfPage"
          :prev-text="prev"
          :next-text="next"
          :container-class="pagination"
          :click-handler="clickCallback"
          :initial-page="0"
        >
        </paginate>
      </div>
      <div
        v-show="permitUserList.length"
        class="d-flex"
        style="padding: 20px; font-size: 15px"
      >
        <div class="has-text-left width-100">
          Total results {{ $store.state.total_users }}
        </div>
      </div>
    </div>

    <!-- Add Permit Check  User Start -->
    <md-dialog
      ref="add-permitchek-user"
      v-if="openedDialog == 'add-permitchek-user'"
      class="dialog-width-60"
      @open="disablescrolling"
      @close="enablescrolling"
    >
      <md-dialog-title
        class="md-dialog-custom-title panel-collapse-header popup-action margin-bottom-0 margin-1"
      >
        <div class="columns input-color-white input-search">
          <div class="columns center-decoration">
            <div class="column d-contents">Add User</div>
            <div class="column pull-right">
              <md-button
                @click="saveUser('add-permitchek-user', userObj)"
                class="md-raised md-primary"
              >
                Save
              </md-button>
              <md-button
                @click="closeDialog('add-permitchek-user')"
                class="md-raised md-primary"
              >
                Close
              </md-button>
            </div>
          </div>
        </div>
      </md-dialog-title>
      <md-dialog-content class="p-15">
        <div class="d-flex width-100">
          <div class="input-data">
            <md-input-container
              :class="{
                'md-input-invalid': errors.has('permitchek-user.first name'),
              }"
            >
              <label>First Name</label>
              <md-input
                type="text"
                tabindex="2"
                v-model="userObj.first_name"
                data-vv-name="first name"
                data-vv-scope="permitchek-user"
                v-validate="{ required: true }"
              >
              </md-input>
              <span class="md-error">{{
                errors.first("permitchek-user.first name")
              }}</span>
            </md-input-container>
          </div>
          <div class="input-data">
            <md-input-container
              :class="{
                'md-input-invalid': errors.has('permitchek-user.last name'),
              }"
            >
              <label>Last Name</label>
              <md-input
                type="text"
                tabindex="2"
                v-model="userObj.last_name"
                data-vv-name="last name"
                data-vv-scope="permitchek-user"
                v-validate="{ required: true }"
              >
              </md-input>
              <span class="md-error">{{
                errors.first("permitchek-user.last name")
              }}</span>
            </md-input-container>
          </div>
          <div class="input-data">
            <md-input-container
              :class="{
                'md-input-invalid': errors.has('permitchek-user.email'),
              }"
            >
              <label>Email</label>
              <md-input
                type="text"
                tabindex="2"
                v-model="userObj.email_address"
                data-vv-name="email"
                data-vv-scope="permitchek-user"
                v-validate="'required|email'"
              >
              </md-input>
              <span class="md-error">{{
                errors.first("permitchek-user.email")
              }}</span>
            </md-input-container>
          </div>
        </div>

        <div class="d-flex width-100">
          <div class="input-data1">
            <md-input-container
              :class="{
                'md-input-invalid': errors.has('permitchek-user.mobile no'),
              }"
            >
              <label>Mobile No</label>
              <md-input
                type="text"
                tabindex="2"
                v-model="userObj.phone_number"
                data-vv-name="mobile no"
                @keyup.native="mobileNumberEvent(1)"
                v-validate="{ required: true, numeric: true, digits: 10 }"
                data-vv-scope="permitchek-user"
              >
              </md-input>
              <span class="md-error">{{
                errors.first("permitchek-user.mobile no")
              }}</span>
            </md-input-container>
          </div>
          <div class="input-data1">
            <md-input-container
              :class="{
                'md-input-invalid': errors.has('permitchek-user.password'),
              }"
            >
              <label>password</label>
              <md-input
                type="text"
                tabindex="2"
                v-model="userObj.password"
                data-vv-name="password"
                data-vv-scope="permitchek-user"
                v-validate="{ required: true }"
              >
              </md-input>
              <span class="md-error">{{
                errors.first("permitchek-user.password")
              }}</span>
            </md-input-container>
          </div>
        </div>
      </md-dialog-content>
    </md-dialog>
    <!-- Add Permit Check  User end -->

    <!-- view Agent/client web page start  -->
    <md-dialog
      @open="disablescrolling"
      @close="enablescrolling"
      class="view-company-pages-dialog"
      v-if="isDisplayPopup"
      :md-click-outside-to-close="false"
      :md-esc-to-close="false"
      ref="view-contact-pages"
    >
      <md-dialog-title class="md-dialog-custom-title contact-pages">
        <div class="d-flex align-items-center">
          <div class="width-100">
            Login Page : {{ currentOpenedContactName }}
          </div>
          <div>
            <md-button
              class="md-raised md-primary"
              @click="closeViewContactPage()"
              >Close</md-button
            >
          </div>
        </div>
      </md-dialog-title>
      <md-dialog-content class="company-login-iframe">
        <iframe
          style="width: 100%; height: 500px;border:none;"
          id="iframeContactView"
          :src="websiteUrl"
        ></iframe>
      </md-dialog-content>
    </md-dialog>
    <!-- view Agent/client web page end  -->

    <!-- Add Permit check upload file dialog Start-->
    <md-dialog
      ref="add-permitchek-file-upload"
      v-if="openedDialog == 'add-permitchek-file-upload'"
      class="dialog-width-60"
      @open="disablescrolling"
      @close="enablescrolling"
    >
      <md-dialog-title
        class="md-dialog-custom-title panel-collapse-header popup-action margin-bottom-0 margin-1"
      >
        <div class="columns">
          <div class="columns center-decoration">
            <div class="column d-contents">Upload Document</div>
            <div class="column pull-right">
              <md-button
                @click="
                  GenerateReportBulk(
                    'PermitCheckScope',
                    'add-permitchek-file-upload',
                    UploadFileUserId
                  )
                "
                class="md-raised md-primary"
              >
                Save
              </md-button>
              <md-button
                @click="closeDialog('add-permitchek-file-upload')"
                class="md-raised md-primary"
              >
                Close
              </md-button>
            </div>
          </div>
        </div>
      </md-dialog-title>
      <md-dialog-content class="p-15">
        <div class="padding-bottom-0 columns is-multiline">
          <div class="column is-12">
            Choose File
            <b> (please select xlsx or xls format file to import File)</b> (<a
              href="https://home-inspection-dev.s3.us-west-2.amazonaws.com/Imported-Template/1682928376900_1682078542619_permitcheck1.xlsx"
              style="color: blue"
              >Download Demo</a
            >)
          </div>
        </div>
        <div class="columns" data-vv-scope="PermitCheckScope">
          <div class="column">
            <dropzone
              style="margin-top: 20px"
              ref="add-permitchek-file-upload"
              :dropzoneFrom="dropzoneFrom"
              :IsXlsxFormat="IsXlsxFormat"
              :bucketName="bucketName"
              :filesize="dropzoneFilesize"
              @onDropzoneError="onDropzoneError"
              @onDropzoneUploadCancel="onDropzoneUploadCancel"
              @onDropzonefileAddedToUpload="onDropzonefileAddedToUpload"
              @onDropzoneFileUploaded="onDropzoneFileUploaded"
            ></dropzone>
          </div>
        </div>
      </md-dialog-content>
    </md-dialog>
    <!-- Add Permit check upload file dialog End-->

    <md-dialog
      @open="disablescrolling"
      @close="enablescrolling"
      ref="viewUploadFile"
      v-if="openedDialog == 'viewUploadFile'"
      class="dialog-width-75"
      id="viewUploadFile"
    >
      <md-dialog-title
        style="margin-bottom: 0px"
        class="md-dialog-custom-title panel-collapse-header popup-action margin-bottom-0 margin-1"
      >
        <div class="columns input-color-white input-search">
          <div class="columns center-decoration">
            <div class="column d-contents">File Details</div>
            <div class="column pull-right">
              <md-button
                @click="closeDialog('viewUploadFile')"
                class="md-raised md-primary"
              >
                Close
              </md-button>
            </div>
          </div>
        </div>
      </md-dialog-title>
      <md-dialog-content style="padding: 0px">
        <div
          class="padding-bottom-0 columns is-multiline"
          :style="{ 'padding-top': ReportFile.length == 0 ? '20px' : '' }"
          v-if="ReportFile.length"
        >
          <md-table class="width-100">
            <md-table-header>
              <md-table-row>
                <md-table-head>#</md-table-head>
                <md-table-head md-numeric>File Name</md-table-head>
                <md-table-head md-numeric>Date</md-table-head>
                <md-table-head md-numeric>Total</md-table-head>
                <md-table-head md-numeric>Report</md-table-head>
                <md-table-head md-numeric>Info</md-table-head>
              </md-table-row>
            </md-table-header>

            <md-table-body v-if="ReportFile && ReportFile.length">
              <md-table-row v-for="(data, index) in ReportFile" :key="index">
                <md-table-cell>
                  <template>{{ index + 1 }}</template>
                </md-table-cell>

                <md-table-cell>
                  <template>
                    <a
                      :href="data.file_url"
                      alt=""
                      style="font-size: 11pt; color: blue"
                      >{{ data.file_name }}</a
                    >
                  </template>
                </md-table-cell>

                <md-table-cell>
                  <template>{{ data.created_date | DateFormate }}</template>
                </md-table-cell>

                <md-table-cell>
                  <template>{{ data.permit_logs.length }}</template>
                </md-table-cell>

                <md-table-cell>
                  <template
                    ><span>{{ data.report }}</span></template
                  >
                </md-table-cell>

                <md-table-cell>
                  <template>
                    <i
                      style="color: #3c3c3c; top: 15px"
                      class="fa-solid fa-circle-info fa-fa-icon cursor-pointer"
                      @click="
                        permitlogdetails('viewReportDetail', data.permit_logs)
                      "
                    >
                      <md-tooltip style="background-color: black;"
                        md-direction="top"
                        class="common-tooltip multi-line"
                        >View File
                      </md-tooltip>
                    </i>
                  </template>
                </md-table-cell>
              </md-table-row>
            </md-table-body>
          </md-table>
        </div>
        <div
          style="margin-bottom: 20px; margin-top: 20px"
          v-else-if="ReportFile.length == 0"
          class="text-align-center"
        >
          <template>
            <span>No File Details Found</span>
          </template>
        </div>
        <div
          class="text-align-center"
          v-show="ReportFile.length"
          style="padding-bottom: 15px"
        >
          <paginate
            ref="refPagintationUser1"
            :page-count="reportNumberOfPage"
            :prev-text="prev"
            :next-text="next"
            :container-class="pagination"
            :click-handler="reportCallBack"
            :initial-page="0"
          >
          </paginate>
        </div>
      </md-dialog-content>
    </md-dialog>

    <md-dialog
      ref="viewReportDetail"
      v-if="openedDialog == 'viewReportDetail'"
      class="dialog-width-60"
      id="viewReportDetail"
      @open="disablescrolling"
      @close="enablescrolling"
    >
      <md-dialog-title
        style="margin-bottom: 0px"
        class="md-dialog-custom-title panel-collapse-header popup-action margin-bottom-0 margin-1"
      >
        <div class="columns input-color-white input-search">
          <div class="columns center-decoration">
            <div class="column d-contents">Report Details</div>
            <div class="column pull-right">
              <md-button
                class="md-raised md-primary"
                @click="openDialog('viewUploadFile')"
                >Go Back</md-button
              >
              <md-button
                @click="closeDialog('viewReportDetail')"
                class="md-raised md-primary"
              >
                Close
              </md-button>
            </div>
          </div>
        </div>
      </md-dialog-title>
      <md-dialog-content style="padding: 0px">
        <div class="padding-bottom-0 columns is-multiline">
          <md-table class="width-100">
            <md-table-header v-if="ReportDetails && ReportDetails.length">
              <md-table-row>
                <md-table-head>#</md-table-head>
                <md-table-head>Address</md-table-head>
                <md-table-head>Report Link</md-table-head>
              </md-table-row>
            </md-table-header>

            <md-table-body v-if="ReportDetails.length">
              <md-table-row
                v-for="(data1, index) in ReportDetails"
                :key="index"
              >
                <md-table-cell
                  ><template>{{ index + 1 }}</template></md-table-cell
                >
                <md-table-cell
                  ><template>{{
                    data1.property_address
                  }}</template></md-table-cell
                >
                <md-table-cell>
                  <template
                    v-if="
                      data1.permit_property == null ||
                      data1.permit_property.report_link == ''
                    "
                    >Not Found</template
                  >
                  <template v-else
                    ><a
                      :href="data1.permit_property.report_link"
                      target="_blank"
                      style="color: blue"
                      >{{
                        data1.permit_property.report_link.substring(
                          data1.permit_property.report_link.lastIndexOf("/") + 1
                        )
                      }}</a
                    ></template
                  >
                </md-table-cell>
              </md-table-row>
            </md-table-body>
          </md-table>
        </div>
      </md-dialog-content>
    </md-dialog>

    <!-- Permitcheck User record info Dialog Start-->
    <md-dialog
      ref="PermitCheckRecord"
      v-if="openedDialog == 'PermitCheckRecord'"
      class="dialog-width-60"
      @open="disablescrolling"
      @close="enablescrolling"
    >
      <md-dialog-content class="p-15">
        <div class="padding-bottom-0 columns is-multiline">
          <div class="column is-12 p-3 text-align-center">
            <b
              >Your Report Limit Is {{ GenerateReport.reports_limit_left }} and
              Your Total Data Is {{ GenerateReport.file_rows }}<br />Your
              Remaing Data Is
              {{ GenerateReport.file_rows - GenerateReport.reports_limit_left
              }}<br />Still You Want to Continue Your Plan?</b
            >
          </div>
        </div>
        <div class="text-align-center">
          <md-button
            class="md-raised md-primary"
            style="color: #fff"
            @click="permitCheckData()"
            >YES</md-button
          >
          <md-button
            class="md-raised md-primary"
            style="color: #fff"
            @click="closeDialog('PermitCheckRecord')"
            >NO</md-button
          >
        </div>
      </md-dialog-content>
    </md-dialog>
    <!-- Permitcheck User record info Dialog End -->

    <!-- permit user login dialog start -->
    <md-dialog
      class="dialog-width-40 user-detail-dialog"
      :md-click-outside-to-close="false"
      :md-esc-to-close="false"
      ref="permituser-detail-dialog"
      @open="disablescrolling"
      @close="enablescrolling"
    >
      <div class="panel">
        <md-dialog-title
          class="md-dialog-custom-title panel-collapse-header popup-action margin-bottom-0 margin-1"
        >
          <div class="columns center-decoration">
            <div class="column d-contents">Login Details</div>
            <div class="column pull-right">
              <md-button
                @click="closepermitUserDialog()"
                class="md-raised md-primary"
              >
                Close
              </md-button>
            </div>
          </div>
        </md-dialog-title>
      </div>
      <md-dialog-content>
        <div class="columns word-break d-flex">
          <div class="column" style="width: 60%">
            <span>Email Address</span>
            <p class="user-text">{{ permituserData.email_address }}</p>
          </div>
          <div class="column is-6" style="width: 40%">
            <span>Password</span>
            <p class="user-text">{{ permituserData.password }}</p>
          </div>
        </div>
      </md-dialog-content>
      <md-dialog-content>
        <div class="columns word-break d-flex">
          <div class="column" style="width: 50%" @click="deleteUser(permituserData.userId)">
            <md-button class="button delete-button">
              <!-- Delete User -->
              <span class="icon">&#128465;</span> <!-- Unicode for a trash can icon -->
                Delete User
            </md-button>
          </div>
          <div v-if="permituserData.isUserActive" class="column is-6" style="width: 50%" @click="disableUser(permituserData.userId)">
            <md-button class="disable-button">
              <span class="icon">&#128683;</span> <!-- Unicode for a no entry icon -->
                Disable User
            </md-button>
          </div>
          <div v-else class="column is-6" style="width: 50%" @click="activateUser(permituserData.userId)">
            <md-button class="button activate-button">
            <span class="icon">&#x2714;</span>
              Activate User
              </md-button>
          </div>
        </div>
        <div class="columns word-break d-flex">
          <div class="column" style="width: 50%">
            <label class="font-weight-600 internachi-user">Internachi User</label>
            <md-checkbox name="checkbox" @change="handleInternachiUserStatusChange($event)" v-model="permituserData.isInternachiUser "></md-checkbox>
          </div>
        </div>
      </md-dialog-content>
    </md-dialog>
    <!-- permit user login dialog End -->
    <div class="permits-pull-count" v-if="showTotalPermitModal">
      <div>
        Total Pulled Permit Reports -  
        <span id="totalReportsCount">
          {{ totalPermitPulls }}
        </span> 
      </div>
      <div>
        Regular API Permit Reports -  
        <span id="normalReportsCount">
          {{ totalPermitPulls - totalCloudPulls }}
        </span> 
      </div>
      <div>
        Cloud Permit Reports -  
        <span id="cloudReportsCount">
          {{ totalCloudPulls }}
        </span> 
      </div>
    </div>
    <confirmationModal
      v-if="showConfirmationModal" 
      :title="confirmationTitle"
      description="Are you sure, you want to proceed this action?"
      @cancel="closeModal"
      @confirm="handleConfirm"
    />
    <!-- <permit-pulls /> -->
  </div>
</template>

<script>
import Paginate from "vuejs-paginate";
import Dropzone from "../dropzone/Dropzone.vue";
import { show, hide } from "../../assets/js/common";
import DateRange from "../../components/dateRange/Daterangenew.vue";
import confirmationModal from "../confirmationModal.vue";
// import PermitPulls from "./PermitPulls.vue";

export default {
  name: "permit-check-user",
  components: {
    Paginate,
    Dropzone,
    DateRange,
    confirmationModal,
    // PermitPulls
  },
  data() {
    return {
      PageRecord: 10,
      numberOfPage: 0,
      totalPermitPulls: 0,
      totalCloudPulls:0,
      prev: "Prev",
      next: "Next",
      pagination: "pagination",
      PageNo: 1,
      RecordsPerPage: 10,
      showLoader: "none",
      searchByApiType: '',
      tipclear: "Clear Search",
      search: "",
      filterStart: "",
      filterEnd: "",
      start: "",
      end: "",
      search_status: "",
      start_date: "",
      end_date: "",
      permitStartDate: "",
      permitEndDate: "",
      searchInterval: null,
      isDisplayPopup: false,
      websiteUrl: "",
      openedDialog: "",
      userObj: {},
      UploadFileUserId: 0,
      ReportDetails: [],
      IsXlsxFormat: true,
      bucketName: "home-inspection-dev/Imported-Template",
      dropzoneFrom: "permitchekDocumentUpload",
      dropzoneFilesize: "50",
      document_url: "",
      file_name: "",
      overlimit: {
        file_name: "",
        user_id: 0,
        file_url: "",
        is_final_submit: 0,
        is_continue: 0,
      },
      is_final_submit: 0,
      IsSaving: false,
      ReportFile: [],
      GenerateReport: [],
      is_continue: 0,
      reportCount: 0,
      reportNumberOfPage: 0,
      permituserData: {
        email_address: "",
        password: "",
        userId: null,
        isUserActive: null,
        isInternachiUser: null
      },
      showTotalPermitModal: true,
      showConfirmationModal: false,
      confirmationTitle: '',
      isInternachiUser: true
    };
  },
  computed: {
    permitUserList: function () {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.numberOfPage = Math.ceil(
        this.$store.state.total_users / this.PageRecord
      );
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.totalCloudPulls = this.$store.state.total_cloud_pulls;
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.totalPermitPulls = this.$store.state.total_permit_pulls;
      return this.$store.state.permitUserList.map((x) => {
        x.property_address = "";
        if (x.inspection && x.inspection.property) {
          let property = x.inspection.property;
          let stateName =
            property && property.state && property.state.state_name
              ? property.state.state_name
              : "";
          let countryName =
            property && property.country && property.country.country_name
              ? property.country.country_name
              : "";
          x.property_address = this.generateAddress(
            this.generateAddressLine(
              property.address_line1,
              property.address_line2
            ),
            property.city,
            this.generateAddressLine(stateName, property.zip_code),
            countryName
          );
        }
        return x;
      });
    },
  },
  mounted: function () {
    this.GetPermitUsersList();
  },
  methods: {
    onDropzoneFileUploaded(file_url) {
      this.IsFileUploding = false;
      this.IsFileUploaded = true;
      this.document_url = file_url;
    },
    onDropzonefileAddedToUpload() {
      this.IsFileUploding = true;
      this.IsFileUploaded = false;
    },
    onFileUploaded(file_url) {
      this.IsFileUploding = false;
      this.IsFileUploaded = true;
      this.document_url = file_url;
    },
    onDropzoneUploadCancel() {
      this.IsFileUploding = false;
      this.IsFileUploaded = false;
      this.document_url = "";
    },
    onDropzoneError: function (response) {
      this.openGlobalSnackbar(response);
    },
    viewLoginDetails(obj) {
      this.permituserData.email_address = obj.email_address;
      this.permituserData.password = obj.password;
      this.permituserData.userId = obj.user_id;
      this.permituserData.isUserActive = obj.is_active;
      this.permituserData.isInternachiUser = obj.is_internachi;
      this.$refs["permituser-detail-dialog"].open();
    },
    closepermitUserDialog() {
      this.$refs["permituser-detail-dialog"].close();
      this.permituserData = {
        email_address: "",
        password: "",
        userId: null,
        isUserActive: null,
        isInternachiUser: null
      };
    },
    enableAccess(data) {
      let payload = {
        user_id: data.user_id,
        enable_api_access: data.enable_api_access ? 0 : 1,
      };
      this.$store.dispatch("permit_check", { payload }).then((response) => {
        if (response && response.res == 0) {
          this.openGlobalSnackbar(response.msg);
        }
      });
    },
    mobileNumberEvent(type) {
      if (type == 1 && this.userObj && this.userObj.phone_number) {
        this.userObj.phone_number = this.userObj.phone_number.replaceAll(
          /[^0-9]/g,
          ""
        );
      }
    },
    saveUser(ref, obj) {
      obj.device_type = "w";
      obj.username = "";
      obj.created_from = "1";

      this.$validator.validateAll("permitchek-user").then((result) => {
        if (result) {
          this.$store
            .dispatch("permitUserRegistration", obj)
            .then((response) => {
              this.openGlobalSnackbar(response.msg);
              if (response.res && response.res == 0) {
                this.GetPermitUsersList();
              }
              this.closeDialog(ref);
            });
        }
      });
    },
    GenerateReportBulk(scope, ref, user_id) {
      var user = localStorage.getItem(btoa(btoa("permitSession")));
      user = atob(atob(user)).toLowerCase();
      user = JSON.parse(user);
      this.IsSaving = true;
      // eslint-disable-next-line no-global-assign
      self = this;
      let file_name = this.document_url.substring(
        this.document_url.lastIndexOf("/") + 1
      );
      this.$validator.validateAll(scope).then(async (result) => {
        if (result) {
          if (this.document_url == "") {
            self.IsSaving = false;
            self.openGlobalSnackbar("Please select File Type");
          } else if (self.IsFileUploding) {
            self.openGlobalSnackbar("Please wait... file is uploading");
            self.IsSaving = false;
          } else {
            let payload = {
              file_name: file_name,
              user_id: user_id ? user_id : 0,
              file_url: this.document_url,
              is_final_submit: this.is_final_submit,
              is_continue: this.is_continue,
              created_by: user && user.user_id ? user.user_id : 0,
            };
            this.$store
              .dispatch("GenerateReportBulk", payload)
              .then((response) => {
                if (response && response.res == 0) {
                  this.GenerateReport = response.data;
                  if (
                    response.data.file_rows >= response.data.reports_limit_left
                  ) {
                    this.NewGenerateReport(
                      "PermitCheckRecord",
                      file_name,
                      user_id,
                      this.document_url,
                      this.is_final_submit,
                      (this.is_continue = 1)
                    );
                  } else {
                    self.openGlobalSnackbar(
                      "Buildchek reports are generated!"
                    );
                    this.closeDialog("add-permitchek-file-upload");
                  }
                } else if (response && response.res == 1) {
                  self.openGlobalSnackbar(response.msg);
                  this.closeDialog("add-permitchek-file-upload");
                }
                this.document_url = "";
              });
          }
        }
      });
    },
    NewGenerateReport(
      ref,
      file_name,
      user_id,
      file_url,
      is_final_submit,
      is_continue
    ) {
      this.overlimit.file_name = file_name;
      this.overlimit.user_id = user_id;
      this.overlimit.file_url = file_url;
      this.overlimit.is_final_submit = is_final_submit;
      this.overlimit.is_continue = is_continue;
      this.openDialog(ref);
    },
    permitCheckData() {
      this.$store.dispatch("GenerateReportBulk", this.overlimit).then(() => {
        this.closeDialog("PermitCheckRecord");
      });
    },
    ReportFileDetails(user_id) {
      let payload = {
        PageNo: this.PageNo,
        PageRecord: this.PageRecord,
        user_id: user_id ? user_id : 0,
        date: new Date(),
      };
      this.$store.dispatch("ReportFileDetails", payload).then((response) => {
        if (response && response.res == 0) {
          this.reportNumberOfPage = Math.ceil(
            response.data.count / this.PageRecord
          );
          this.ReportFile = response.data.rows;
          this.openDialog("viewUploadFile", user_id);
        }
      });
    },
    permitlogdetails(ref, data) {
      this.ReportDetails = data;
      this.openDialog(ref);
    },
    reportCallBack: function (PageNo) {
      (this.PageNo = PageNo), this.ReportFileDetails(this.UploadFileUserId);
    },
    closeDialog(ref) {
      this.$refs[ref].close();
      this.openedDialog = "";
      this.userObj = {};
      this.document_url = "";
      this.is_continue = 0;
      this.$validator.validateAll().then(result => {
        if (result === false) {
          this.$validator.reset();
          this.$validator.errors.items = [];
        }
      });
        this.enablescrolling();
    },
    openDialog(ref, userId) {
      this.UploadFileUserId = userId;
      this.openedDialog = ref;

      this.$validator.validateAll().then(result => {
        if (result === false) {
          this.$validator.reset();
          this.$validator.errors.items = [];
        }
      });
      setTimeout(() => {
        if (this.$refs && this.$refs[ref]) {
          this.$refs[ref].open();
        }
      }, 100);
    },
    searchData: function () {
      clearInterval(this.searchInterval);
      this.searchInterval = setInterval(() => {
        clearInterval(this.searchInterval);
        this.refresh();
        this.GetPermitUsersList();
      }, 500);
    },
    clearSearch: function () {
      this.search = "";
      this.refresh();
      this.GetPermitUsersList();
    },

    clickCallback: function (PageNo) {
      this.PageNo = PageNo;
      this.GetPermitUsersList();
    },
    GetPermitUsersList() {
      let payload = {
        PageNo: this.PageNo,
        PageRecord: this.PageRecord,
        search: this.search,
        start_date: this.start_date,
        end_date: this.end_date,
        permit_type: this.searchByApiType,
        permit_start_date:this.permitStartDate,
        permit_end_date:this.permitEndDate,
      };
      this.$store
        .dispatch("permitUsersList", {
          payload,
        })
        .then((response) => {
          if (response && response.res == 1) {
            this.openGlobalSnackbar(response.msg);
          }
        });
    },
    refresh() {
      this.PageNo = 1;
      if (this.$refs.refPagintation) {
        this.$refs.refPagintation.selected = 0;
      }
    },
    updateDateFilter: function (data) {
      var dat = data.split(" - ");
      this.start_date = dat[0];
      this.end_date = dat[1];
      this.refresh();
      this.GetPermitUsersList();
    },
    updatePermitRangeDate: function(data) {
      const dat = data.split(" - ");
      this.permitStartDate = dat[0];
      this.permitEndDate = dat[1];
      this.refresh();
      this.GetPermitUsersList();
    },
    clearDate: function () {
      this.start_date = "";
      this.end_date = "";
      this.GetPermitUsersList();
    },
    handleInternachiUserStatusChange: function(event) {
      const payload = {
        user_id: this.permituserData.userId,
        is_internachi: event == true ? '1' : '0'
      }
      this.$store
        .dispatch("updateInterNachiUser", payload)
        .then((response) => {
          if(response.res == 0) {
            this.openGlobalSnackbar(response.msg);
            this.closepermitUserDialog();
            this.GetPermitUsersList();
          }
        })
    },
    clearPermitReportFilter: function() {
      this.permitStartDate ="";
      this.permitEndDate = "";
      this.GetPermitUsersList();
    },
    viewContactpages: function (contact) {
      let token = "";
      token = localStorage.getItem(btoa(btoa("permitAuthKey")));
      token = atob(atob(token));
      this.showTotalPermitModal = false;
      show(); 
      this.currentOpenedContactName =
        contact.first_name + " " + contact.last_name;
      // this.websiteUrl =
      //   "http://permitchek-dev.s3-website-us-west-2.amazonaws.com/#/login?emailId=" +
      //   encodeURIComponent(contact.email_address) +
      //   "&Password=" +
      //   encodeURIComponent(contact.password);
      this.websiteUrl =
      "https://user.buildchek.com/#/login?emailId=" +
      encodeURIComponent(contact.email_address) +
      "&token=" +
      encodeURIComponent(token);
      this.isDisplayPopup = true;
      setTimeout(() => {
        this.$refs["view-contact-pages"].open();
        // eslint-disable-next-line no-undef
        $("#iframeContactView").on("load", function () {
          hide();
        });
      }, 1000);
    },
    closeViewContactPage: function () {

      this.$refs["view-contact-pages"].close();
      this.currentOpenedContactName = "";
      this.showTotalPermitModal = true;
      // setTimeout(() => {
      //   this.isDisplayPopup = false;
      //   this.enablescrolling();
      // }, 500);
    },
    sendCredsOnMail(row){
      this.$store
        .dispatch("sendCredentialsOnEmail", {
          user_id: row.user_id
        })
        .then((response) => {
          if(response.res == 0) {
            this.openGlobalSnackbar(response.msg);
            this.closepermitUserDialog();
          }
        })
    },
    deleteUser : function () {  
      this.confirmationTitle = 'Delete User'  
      this.showConfirmationModal= true;
    },
    disableUser : function () {  
      this.confirmationTitle = 'Disable User'  
      this.showConfirmationModal= true;
    },
    activateUser: function() {
      this.confirmationTitle = 'Activate User'  
      this.showConfirmationModal= true;
    },
    closeModal() {
      this.showConfirmationModal = false;
    },
    
    handleConfirm() {  
      this.showConfirmationModal = false;
      const lowerCaseTitle = this.confirmationTitle.toLowerCase().replace(/\s/g, '');
      const payload = {
        user_id: this.permituserData.userId
      }
      if (lowerCaseTitle == 'deleteuser') {
        this.$store
          .dispatch("permitUserDelete", payload)
          .then((response) => {
            if(response.res == 0) {
              this.openGlobalSnackbar(response.msg);
              this.closepermitUserDialog();
              this.GetPermitUsersList();
            }
          })
        return;
      }
      if (lowerCaseTitle == 'disableuser') {
        this.$store
          .dispatch("permitUserDisable", payload)
          .then((response) => {
            if(response.res == 0) {
              this.openGlobalSnackbar(response.msg);
              this.closepermitUserDialog();
              this.GetPermitUsersList();
            }
        })
        return;
      }
      if (lowerCaseTitle == 'activateuser') {
        this.$store
          .dispatch("permitUserActivate", payload)
          .then((response) => {
            if(response.res == 0) {
              this.openGlobalSnackbar(response.msg);
              this.closepermitUserDialog();
              this.GetPermitUsersList();
            }
          })
        return;
      }
      
    }
  },
  beforeDestroy() {
    this.searchInterval && clearInterval(this.searchInterval);
  },
  
};
</script>

<style scoped>
input.md-input::placeholder {
  color: #fff !important;
}

.permits-pull-count {
  position: fixed;
  bottom: 15px;
  right: 10px;
  background-color: #fff; /* Adjust background color as needed */
  padding: 10px; /* Reduced padding for a more compact look */
  border: 1px solid #ccc; /* Add border for visibility */
  border-radius: 5px;
  z-index: 1000; /* Ensures it stays on top of other elements */
  font-size:large; /* Adjust font size as needed */
  border: 1px solid #0288d1;
}

.permits-pull-count div {
  margin-bottom: 5px; /* Add spacing between each section */
}

.permits-pull-count span {
  font-weight: bold; /* Make the count numbers bold */
}

.delete-button {
  background-color: #e74c3c;
  color: white;
  border-radius: 5px;
}

.disable-button {
    background-color: #f39c12;
    color: white;
    border-radius: 5px;
  }

  .disable-button:hover {
    color: rgb(255, 255, 255);
    background-color: #f3ba5f !important;
  }

  .delete-button:hover{
    color: rgb(255, 255, 255);
    background-color: #fb8679 !important;
  }
  .activate-button {
    background-color: rgb(95, 181, 95);
    display: flex;
    align-items: center;
    color: rgb(255, 255, 255);
  }

  .activate-button:hover {
    color: rgb(255, 255, 255);
    background-color: rgb(126, 198, 126) !important;
  }

  .internachi-user {
    margin: 0px 10px;
    font-size: 16px;
  }

</style>
