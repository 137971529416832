<template>
  <div>
    <div class="pricing-container">
      <div class="pricing-head">
        <span>Buildchek Pricing</span>
      </div>
      <div class="panel-collapse-body warranty-setup p-l-20 p-r-20">
        <md-card md-with-hover>
          <md-card-header class="m-b-0 text-align-left columns">
            <div class="column is-6">
              <div class="md-title">Buildchek Pricing</div>
              <div class="md-subhead text-align-start">
                ${{ permitchek_price }}
              </div>
            </div>
            <div class="column is-6 margin-auto">
              <md-button
                @click="openDialog('add-permitchek-plan')"
                class="md-raised pull-right text-transform-none"
              >
                Edit Buildchek Pricing</md-button
              >
            </div>
          </md-card-header>
        </md-card>
      </div>
    </div>
    <md-dialog ref="add-permitchek-plan" v-if="openedDialog.includes('add-permitchek-plan')" @open="disablescrolling"
      @close="enablescrolling">
      <md-dialog-title class="md-dialog-custom-title m-0 p-15" >
        <span>Edit Buildchek Pricing</span>
      </md-dialog-title>
      <md-dialog-content class="p-15">
        <div class="padding-bottom-0 pricing-dialog">
          <md-input-container
            :class="{ 'md-input-invalid': errors.has('EditPermitchek.Permitchek Price') }"
            data-vv-scope="EditPermitchek"
          >
            <label>Buildchek Price($)</label>
            <md-input
              v-model="amount"
              data-vv-name="Permitchek Price"
              data-vv-scope="EditPermitchek"
              v-validate="{
                required: true,
                decimal:2,
              }"
            ></md-input>
            <span class="md-error">{{ errors.first("EditPermitchek.Permitchek Price") }}</span>
          </md-input-container>
        </div>
      </md-dialog-content>
      <md-dialog-actions>
        <md-button class="permit-btn" @click="updateBuildFaxPrice"
          >save</md-button
        >
        <md-button
          class="permit-btn"
          @click="closeDialog('add-permitchek-plan')"
          >close</md-button
        >
      </md-dialog-actions>
    </md-dialog>

    <!-- Subscription Plan -->

    <div class="pricing-container">
      <div class="subscription-head">
        <span>Normal Subscription Plan</span>
        <div>
          <md-button
            class="md-raised md-primary"
            @click="addSubscriptionPlanType='normal',openEditAddDialog('add-subscription-plan', 0)"
          >
            ADD SUBSCRIPTION PLAN
          </md-button>
        </div>
      </div>
      <div class="SubscriptionPlan ">
        <div class="company-table overflow-auto">
          <div class="no-records" v-if="isLoading">
            <h3 style="padding: 50px !important;">Loading...</h3>
          </div>
          <div
            class="grid-container"
            percentPosition="true"
            v-else-if="SubscriptionPlan && SubscriptionPlan.length"
          >
            <div class="grid-item"
             v-for="(item, index) in SubscriptionPlan"
              :key="index">
              <div>
                <md-card md-with-hover>
                <md-card-header class="background-gray">
                  <div class="md-title">
                    ${{ item.price }}/{{ item.validity_in_days + " Days" }}
                  </div>
                  <div class="md-subhead">{{ item.title }}</div>
                </md-card-header>
                <md-card-content>
                  <md-list>
                    <md-list-item
                      v-for="(f, index) in item.features.split('<&&>')"
                      :key="index"
                    >
                      {{ f }}
                    </md-list-item>
                  </md-list>
                </md-card-content>
                <md-card-actions class="background-gray">
                  <md-button
                    class="md-raised"
                    v-bind:class="[item.is_active ? ' btn-red' : ' btn-green']"
                    @click="
                      openConfirmDailog(
                        'confirm',
                        item.is_active,
                        item.subscription_id
                      )
                    "
                  >
                    {{ item.is_active ? "De-Active" : "Active" }}
                  </md-button>
                  <md-button
                    class="md-raised"                   
                     @click="
                       addSubscriptionPlanType = 'normal',
                      openEditAddDialog(
                        'add-subscription-plan',
                        item.subscription_id
                      )
                    "
                  >
                    Edit</md-button
                  >
                  <md-button
                    class="md-raised"
                    @click="
                      openConfirmDeleteDailog('confirmd', item.subscription_id)
                    "
                    v-if="!item.is_system"
                    >Delete</md-button
                  >
                </md-card-actions>
              </md-card>
              </div>
            </div>
          </div>
          <div v-else class="no-records"  style='padding:20px'>No Subscription Plan Available.</div>
        </div>
      </div>
    </div>


    <!-- Internachi Subscription Plan -->
    <div class="pricing-container">
      <div class="pricing-head">
        <span>Internachi Single Report</span>
      </div>
      <div class="panel-collapse-body warranty-setup p-l-20 p-r-20">
        <md-card md-with-hover>
          <md-card-header class="m-b-0 text-align-left columns">
            <div class="column is-6">
              <div class="md-title">Internachi Single Report</div>
              <div class="md-subhead text-align-start">
                ${{ internachiSingleReportPrice }}
              </div>
            </div>
            <div class="column is-6 margin-auto">
              <md-button
                @click="singleReportType='internachi', openDialog('add-permitchek-plan')"
                class="md-raised pull-right text-transform-none"
              >
                Edit Buildchek Pricing</md-button
              >
            </div>
          </md-card-header>
        </md-card>
      </div>
    </div>
    <div class="pricing-container">
      <div class="subscription-head">
        <span>Internachi Subscription Plan</span>
        <div>
          <md-button
            class="md-raised md-primary"
            @click="addSubscriptionPlanType='internachi', openEditAddDialog('add-subscription-plan', 0)"
          >
            ADD SUBSCRIPTION PLAN
          </md-button>
        </div>
      </div>
      <div class="SubscriptionPlan ">
        <div class="company-table overflow-auto">
          <div class="no-records" v-if="isLoading">
            <h3 style="padding: 50px !important;">Loading...</h3>
          </div>
          <div
            class="grid-container"
            percentPosition="true"
            v-else-if="InternachiSubscriptionPlan && InternachiSubscriptionPlan.length"
          >
            <div class="grid-item"
             v-for="(item, index) in InternachiSubscriptionPlan"
              :key="index">
              <div>
                <md-card md-with-hover>
                <md-card-header class="background-gray">
                  <div class="md-title">
                    ${{ item.price }}/{{ item.validity_in_days + " Days" }}
                  </div>
                  <div class="md-subhead">{{ item.title }}</div>
                </md-card-header>
                <md-card-content>
                  <md-list>
                    <md-list-item
                      v-for="(f, index) in item.features.split('<&&>')"
                      :key="index"
                    >
                      {{ f }}
                    </md-list-item>
                  </md-list>
                </md-card-content>
                <md-card-actions class="background-gray">
                  <md-button
                    class="md-raised"
                    v-bind:class="[item.is_active ? ' btn-red' : ' btn-green']"
                    @click="
                      openConfirmDailog(
                        'confirm',
                        item.is_active,
                        item.subscription_id
                      )
                    "
                  >
                    {{ item.is_active ? "De-Active" : "Active" }}
                  </md-button>
                  <md-button
                    class="md-raised"                   
                     @click="
                      addSubscriptionPlanType = 'internachi',
                      openEditAddDialog(
                        'add-subscription-plan',
                        item.subscription_id
                      )
                    "
                  >
                    Edit</md-button
                  >
                  <md-button
                    class="md-raised"
                    @click="
                      openConfirmDeleteDailog('confirmd', item.subscription_id)
                    "
                    v-if="!item.is_system"
                    >Delete</md-button
                  >
                </md-card-actions>
              </md-card>
              </div>
            </div>
          </div>
          <div v-else class="no-records"  style='padding:20px'>No Subscription Plan Available.</div>
        </div>
      </div>
    </div>

    <!-- ADD/EDIT subscription plan start-->
    <!-- Confirm Active / Deactive Start -->
    <md-dialog ref="confirm" @open="disablescrolling"
      @close="enablescrolling">
      <md-dialog-title class="dialog-title-text">{{ confirmMessage }} </md-dialog-title>
      <md-dialog-actions>
        <md-button @click="closeDialog('confirm')" class="md-primary"
          >Cancel</md-button
        >
        <md-button @click="okChangeStatus('confirm')" class="md-primary"
          >Ok</md-button
        >
      </md-dialog-actions>
    </md-dialog>
    <!-- Confirm Active / Deactive End -->

    <!-- Confirm delete Start -->
    <md-dialog ref="confirmd" @open="disablescrolling"
      @close="enablescrolling">
      <md-dialog-title class="dialog-title-text">{{ confirmMessage }}</md-dialog-title>
      <md-dialog-actions>
        <md-button @click="closeDialog('confirmd')" class="md-primary"
          >Cancel</md-button
        >
        <md-button @click="okDelete('confirmd')" class="md-primary"
          >Ok</md-button
        >
      </md-dialog-actions>
    </md-dialog>
    <!-- Confirm delete End -->
    <md-dialog @open="disablescrolling"
      @close="enablescrolling" ref="add-subscription-plan" class="dialog-width-95" v-if="openedDialog.includes('add-subscription-plan')">
      <md-dialog-title
        class="md-dialog-custom-title panel-collapse-header popup-action margin-bottom-0 margin-1"
      >
        <div class="columns input-color-white input-search">
          <div class="columns center-decoration">
            <div class="column d-contents">Subscription Plan</div>
            <div class="column pull-right">
              <md-button
                class="md-raised md-primary"
                @click="saveSubscriptionPlan('add-subscription-plan')"
              >
                Save
              </md-button>
              <md-button
                class="md-raised md-primary"
                @click="closeDialog('add-subscription-plan')"
              >
                Close
              </md-button>
            </div>
          </div>
        </div>
      </md-dialog-title>
      <md-dialog-content class="p-15">
        <div class="full-width">
          <div class="w-40">
            <md-input-container
              :class="{ 'md-input-invalid': errors.has('subscription.Plan Name') }"
            >
              <label>Plan Name</label>
              <md-input
                type="text"
                data-vv-name="Plan Name"
                data-vv-scope="subscription"
                v-validate="'required'"
                v-model="blankObj.title"
              ></md-input>
              <span class="md-error">{{ errors.first("subscription.Plan Name") }}</span>
            </md-input-container>
          </div>
          <div class="w-20">
            <md-input-container
              :class="{ 'md-input-invalid': errors.has('subscription.Number of Reports') }"
            >
              <label for="Number of Reports">Number of Reports</label>
              <md-input
                type="text"
                data-vv-scope="subscription"
                v-model="blankObj.report_limit"
                data-vv-name="Number of Reports"
                v-validate="'required|numeric'"
              >
              </md-input>
              <span class="md-error">{{
                errors.first("subscription.Number of Reports")
              }}</span>
            </md-input-container>
          </div>
          <div class="w-20">
            <md-input-container
              :key="'planPeriod_' + dynamickey"
              :class="{ 'md-input-invalid': errors.has('subscription.Plan Period') }"
            >
              <label for="Plan Period">Plan Period</label>
              <md-select
                name="PlanPeriod"
                data-vv-name="Plan Period"
                data-vv-scope="subscription"
                placeholder="Select Plan Period"
                v-validate="'required'"
                v-model="blankObj.subscription_frequency"
              >
                <md-option :disabled="true" selected
                  >Select Plan Period</md-option
                >
                <md-option
                  v-for="(type, index) in planTimePeriod"
                  :key="index"
                  :value="type.value"
                  >{{ type.name }}
                </md-option>
              </md-select> 
              <span class="md-error">{{ errors.first("subscription.Plan Period") }}</span>
            </md-input-container>
          </div>
          <div class="w-20">
            <md-input-container
              :class="{ 'md-input-invalid': errors.has('subscription.Plan Amount') }"
            >
              <label>Plan Amount($)</label>
              <md-input
                type="text"
                data-vv-name="Plan Amount"
                data-vv-scope="subscription"
                v-validate="'required|decimal:3'"
                v-model="blankObj.price"
              ></md-input>
              <span class="md-error">{{ errors.first("subscription.Plan Amount") }}</span>
            </md-input-container>
          </div>
        </div>
        <div class="full-width" style="padding-left: 10px; display: block;" >
          <md-chips
          :class="{ 'md-input-invalid': errors.has('subscription.Plan_Features') }"
            md-input-placeholder="Plan Features(Type and hit enter..)"
            v-model="features"
            data-vv-name="'Plan_Features'"
            data-vv-scope="subscription"
            v-validate="'required'"
            style="margin: 0 !important;"
          >
        </md-chips>
        <span class="md-error" v-if="mdchips" style="color: #ff5722;">{{ this.plan_feature }}</span>
        </div>
        <div class="full-width">
          <div class="w-40">
            <md-input-container
              :class="{
                'md-input-invalid': errors.has('subscription.Additional Charge report'),
              }"
            >
              <label>Additional Charge report</label>
              <md-input
                type="text"
                v-model="blankObj.charge_per_report"
                data-vv-name="Additional Charge report"
                data-vv-scope="subscription"
                v-validate="'required|decimal'"
              >
              </md-input>
              <span class="md-error">{{
                errors.first("subscription.Additional Charge report")
              }}</span>
            </md-input-container>
          </div>
          <div class="w-20">
            <md-checkbox v-model="blankObj.is_visible" class="md-primary">
              Is visible to user?
            </md-checkbox>
          </div>
        </div>
      </md-dialog-content>
    </md-dialog>

    <md-dialog ref="confirm" @open="disablescrolling"
      @close="enablescrolling">
      <md-dialog-title class="dialog-title-text">{{ confirmMessage }} </md-dialog-title>
      <md-dialog-actions>
        <md-button @click="closeDialog('confirm')" class="md-primary"
          >Cancel</md-button
        >
        <md-button @click="okChangeStatus('confirm')" class="md-primary"
          >Ok</md-button
        >
      </md-dialog-actions>
    </md-dialog>
    <!-- ADD/EDIT subscription plan end-->

    <!-- Payment services -->

    <div class="pricing-container">
      <div class="payment-head">
        <span>Payment Services</span>
        <div class="header-action">
          <div v-show="isVisibleAdd">
            <md-button
              class="md-raised md-primary"
              id="add_payment_btn"
              @click="addNewPayment"
            >
              Add
            </md-button>
          </div>
          <div class="action-button pull-right" v-show="isVisibleClose" @click="close">
            <md-button class="md-raised md-primary"> Close </md-button>
          </div>
          <div class="action-button pull-right" v-show="isVisibleSave" @click="SavePaymentService">
            <md-button class="md-raised md-primary"> Save </md-button>
          </div>
        </div>
      </div>
      <div class="panel-block textalign-left p-15">
        <div class="no-records" v-if="isLoading">
          <h3 style="padding: 50px !important;">Loading...</h3>
        </div>
        <div class="width-100 payment" v-else-if="paymentservices && paymentservices.length && !isLoading">
          <div
            class="columns"
            v-for="(row, index) in paymentservices"
            :data-index="index"
            :key="row.company_payment_service_id"
          >
            <div class="column is-2">
              <div class="t2-pixel" v-show="!row.isEditMode">
                <span class="s6-pixel">{{ row.payment_service_type }}</span>
                <br />
                <p class="p-tag-color">Service</p>
              </div>
              <span class="questioan-top" v-show="row.isEditMode">
                <md-input-container>
                  <md-select
                    id="payment-select"
                    v-model="row.payment_service_id"
                    data-vv-name="row.payment_service_id"
                  >
                    <md-option disabled value="" selected
                      >Please select one</md-option
                    >
                    <md-option
                      v-for="option in paymentservicetypes"
                      :key="option.payment_service_id"
                      :value="option.payment_service_id"
                      >{{ option.service_name }}</md-option
                    >
                  </md-select>
                </md-input-container>
              </span>
            </div>
            <div class="column is-8 payment-details" v-if="row.payment_service_id == 2">
              <!-- <div class="payment-details" > -->
                <!-- Authorize NET SETUP START -->
                <div
                  class="column is-6"
                  v-if="row.payment_service_id == 2 && !row.isEditMode"
                >
                  <div class="t2-pixel">
                    <span class="s6-pixel">{{ row.Login_ID }}</span>
                    <br />
                    <p class="p-tag-color">Login Id</p>
                  </div>
                </div>
                <div
                  class="column is-6 padding-left-338"
                  v-if="row.payment_service_id == 2 && !row.isEditMode"
                >
                  <div class="t2-pixel">
                    <!-- <span class="s6-pixel">{{row.Transaction_Key}}</span> -->
                    <span class="s6-pixel"
                      >{{ symchar(row.Transaction_Key)
                      }}<sub class="syschars">******</sub></span
                    >
                    <br />
                    <p class="p-tag-color">Transaction Key</p>
                  </div>
                </div>
                <div style="padding: 0px 15px;"
                  class="column is-4"
                  v-if="row.payment_service_id == 2 && row.isEditMode"
                >
                <div>
                    <md-input-container :class="{'md-input-invalid': errors.has('Authorize.Login ID')}">
                        <label for="Login_ID">Login Id</label>
                        <md-input data-vv-name="Login ID" v-model="PaymentServiceData.Login_ID"
                            :key="'Login_ID'+row.payment_service_id" type="text" data-vv-as="Login ID"
                            v-validate data-vv-rules="required" data-vv-scope="Authorize">
                        </md-input>
                        <span class="md-error">{{errors.first('Authorize.Login ID')}}</span>
                    </md-input-container>
                </div>
                </div>
                <div
                  class="column is-4" style="padding: 0px 15px;"
                  v-if="row.payment_service_id == 2 && row.isEditMode"
                >
                <div>
                    <md-input-container :class="{'md-input-invalid': errors.has('Authorize.Transaction Key')}">
                        <label for="Transaction_Key">Transaction Key</label>
                        <md-input data-vv-name="Transaction Key" v-model="PaymentServiceData.Transaction_Key"
                            data-vv-as="Transaction Key" type="text" v-validate data-vv-rules="required" data-vv-scope="Authorize">
                        </md-input>
                        <span class="md-error">{{errors.first('Authorize.Transaction Key')}}</span>
                    </md-input-container>
                </div>
                </div>
                <div style="padding: 0px 15px;"
                  class="column is-4 validate-button"
                  v-if="row.payment_service_id == 2 && row.isEditMode"
                >
                  <md-button
                    class="md-raised md-primary"
                    @click="ValidateAuthorizeNetService"
                    style="background-color: #0288d1 !important;; color: #fff!important;top:18px !important;"
                    >Validate & Save</md-button
                  >
                </div>

                <!-- Authorize NET SETUP  END -->
              <!-- </div> -->
            </div>
            <div class="column is-8 payment-details" v-if="row.payment_service_id == 4">
              <!-- <div class="payment-details" > -->
                <!-- Authorize NET SETUP START -->
                <div
                  class="column is-6"
                  v-if="row.payment_service_id == 4 && !row.isEditMode"
                >
                  <div class="t2-pixel">
                    <span class="s6-pixel">{{ row.Merchant_User_Name }}</span>
                    <br />
                    <p class="p-tag-color">Merchant Username</p>
                  </div>
                </div>
                <div
                  class="column is-6 padding-left-300"
                  v-if="row.payment_service_id == 4 && !row.isEditMode"
                >
                  <div class="t2-pixel">
                    <!-- <span class="s6-pixel">{{row.Transaction_Key}}</span> -->
                    <span class="s6-pixel"
                      >{{ symchar(row.Merchant_Password)
                      }}<sub class="syschars">******</sub></span
                    >
                    <br />
                    <p class="p-tag-color">Merchant Password</p>
                  </div>
                </div>
                <div style="padding: 0px 15px;"
                  class="column is-4"
                  v-if="row.payment_service_id == 4 && row.isEditMode"
                >
                <div>
                    <md-input-container :class="{'md-input-invalid': errors.has('Merchant.Merchant User Name')}">
                        <label for="Merchant_User_Name">Merchant Username</label>
                        <md-input data-vv-name="Merchant User Name" v-model="PaymentServiceData.Merchant_User_Name"
                            type="text" data-vv-scope="Merchant" data-vv-as="Merchant Username" v-validate data-vv-rules="required">
                        </md-input>
                        <span class="md-error">{{errors.first('Merchant.Merchant User Name')}}</span>
                    </md-input-container>
                </div>
                </div>
                <div style="padding: 0px 15px;"
                  class="column is-4"
                  v-if="row.payment_service_id == 4 && row.isEditMode"
                >
                <div>
                    <md-input-container :class="{'md-input-invalid': errors.has('Merchant.Merchant Password')}">
                        <label for="Merchant_Password">Merchant Password</label>
                        <md-input class="font-size-25" data-vv-name="Merchant Password"
                            v-model="PaymentServiceData.Merchant_Password" data-vv-scope="Merchant" data-vv-as="Merchant Password"
                            type="Password" v-validate data-vv-rules="required">
                        </md-input>
                        <span class="md-error">{{errors.first('Merchant.Merchant Password')}}</span>
                    </md-input-container>
                </div>
                </div>
                <div
                  class="column is-4 validate-button"
                  v-if="row.payment_service_id == 4 && row.isEditMode"
                >
                  <md-button
                    class="md-raised md-primary"
                    @click="ValidateSignatureCardService"
                    style="background-color: #0288d1 !important; color: #fff!important;top: 18px;"
                    >Validate & Save</md-button
                  >
                </div>

                <!-- Authorize NET SETUP  END -->
              <!-- </div> -->
            </div>
            <div class="column is-8 payment-details" v-if="row.payment_service_id == 7">
              <!-- <div class="payment-details" > -->
                <!-- Authorize NET SETUP START -->
                <div
                  class="column is-6"
                  v-if="row.payment_service_id == 7 && !row.isEditMode"
                >
                <div class="t2-pixel">
                    <span class="s6-pixel">{{ symchar(row.apiKey) }}<sub
                        class="syschars">******</sub></span>
                    <br>
                    <p class="p-tag-color">API Key</p>
                </div>
                </div>
                <div
                  class="column is-4"
                  v-if="row.payment_service_id == 7 && row.isEditMode"
                  style="margin-left: 20px;"
                >
                  <div>
                      <md-input-container :class="{'md-input-invalid': errors.has('fluidpay.API KEY')}">
                          <label for="API_KEY">Api Key</label>
                          <md-input data-vv-name="API KEY" v-model="PaymentServiceData.apiKey"
                              data-vv-as="API Key" type="text" v-validate data-vv-rules="required" data-vv-scope="fluidpay">
                          </md-input>
                          <span class="md-error">{{errors.first('fluidpay.API KEY')}}</span>
                      </md-input-container>
                  </div>
                </div>
                <div
                  class="column is-4 validate-button"
                  v-if="row.payment_service_id == 7 && row.isEditMode"
                  style="margin-left: 20px;"
                >
                <md-button class="md-raised md-primary" @click="ValidateFluidPay"
                style="background-color: #0288d1 !important; color: #fff!important;top: 18px;">Validate & Save</md-button>
                </div>

                <!-- Authorize NET SETUP  END -->
              <!-- </div> -->
            </div>
            <div class="column is-8 d-flex align-items-center">
              <div v-if='row.IsAddingNew'
              class="square-menu" style="font-size: 23px;" :style="{'padding-left':([2,4,7].includes(row.payment_service_id))?'0':'29px'}">
              <a @click="openDialog('confirmdel')">
                <delete-icon class="black" :title="tipdeletepayment" />
              </a>
              </div>
              <div class="square-menu" style="font-size: 23px;"
                  v-if='!row.IsAddingNew'
                  @click="openDialog('changePaymentService',row.permit_default_payment_service_id)"
                 >
                  <a>
                      <checkbox-marked v-if="row.is_default" class="IsDefalutColor" :title="tipdefaultpayment" />
                      <checkbox-blank-outline v-if="!row.is_default" class="IsNotDefalutColor"
                          :title="tipsetdefaultpayment" />
                  </a>
              </div>
          </div>
          
          </div>
        </div>
        <div v-else class="no-records">
          <h3>No Payment Service Available.</h3>
        </div>
        
      </div>
      <md-dialog ref="confirmdel" @open="disablescrolling"
      @close="enablescrolling">
        <md-dialog-title class="dialog-title-text">Are you sure you want to delete payment service?</md-dialog-title>
          <md-dialog-actions>
                              <md-button class="md-primary" @click="closeDialog('confirmdel')">Cancel</md-button>
                              <md-button class="md-primary" @click="okDialog()">Ok</md-button>
                          </md-dialog-actions>
                      </md-dialog>
      <md-dialog ref="changePaymentService" @open="disablescrolling"
      @close="enablescrolling">
              <md-dialog-title class="dialog-title-text">Are you sure you want to change default payment service?
              </md-dialog-title>
              <md-dialog-content>
                  <p class="has-text-danger"><b>Note:</b> If you change the default payment service then the auto subscription
                      feature will not work. You need to inform companies to renew their subscription manually with credit
                      card details.</p>
              </md-dialog-content>
              <md-dialog-actions>
                  <md-button class="md-primary" @click="closeDialog('changePaymentService')">Cancel</md-button>
                  <md-button class="md-primary" @click="okDefaultPayemntService('changePaymentService')">Ok</md-button>
              </md-dialog-actions>
          </md-dialog>
    </div>
  </div>
</template>


<script>
// import eventBus from '../../common-plugin/eventbus';
import { show,hide } from '../../assets/js/common';
import checkboxMarked from '../icon/checkbox-marked.vue'
import checkboxBlankOutline from '../icon/checkbox-blank-outline.vue'
export default {
  name: "permit-check-pricing",
  components:{checkboxMarked,checkboxBlankOutline},
  data() {
    return {
      // pricing
      singleReportType: '',
      addSubscriptionPlanType: null,
      amount: 0.0,
      permitchek_price: 0.0,
      internachiSingleReportPrice: 0.0,
      // subscription
      is_active: "",
      tipdefaultpayment: "Default Payment Service",
      tipsetdefaultpayment: 'Set As Default Payment Service',
      tipdeletepayment: 'Delete Payment Service',
      confirmMessage: "",
      selectedSubscription: "",
      planType: [
        { value: 1, name: "Main Plan" },
        { value: 2, name: "Additional Inspector Plan" },
        { value: 3, name: "Add On Package" },
      ],
      planTimePeriod: [
        { value: "single", name: "Single Report"},
        { value: "15", name: "Half Month" },
        { value: "30", name: "Monthly" },
        { value: "90", name: "Quaterly" },
        { value: "180", name: "Six Months" },
        { value: "365", name: "Yearly" },
      ],
      blankObj: {
        subscription_id: 0,
        title: "",
        price: "",
        validity_in_days: "",
        subscription_frequency: "",
        features: "",
        allowed_devices: "",
        currency_symbol: "$",
        plan_type: "",
        allow_qty: false,
        user_id: "",
        is_visible: true, // if true then this plan can be buy by company else only superadmin and salesperson can use this
        report_limit: "",
        charge_per_report: "",
        is_internachi: null
      },
      dynamickey: 0,
      features: [],
      plan_feature:'',
      // payment
      isEditable: false,
      isEditMode: true,
      isVisibleAdd: true,
      isVisibleClose: false,
      isVisibleSave: false,
      companyID: 0,
      paymentservicetypes: [],
      updated_paymentservicetypes: [],
      id: 0,
      PaymentServiceData: {
        payment_service_id:'',
        Merchant_User_Name:'',
        Merchant_Password:'',
        payment_service_type:'',
        isEditingMode:true,
        Login_ID:'',
        Transaction_Key:'',
        apiKey:'',
      },
      PaymentGatwayResponse: [],
      company_payment_service_id:0,
      openedDialog: [],
    };
  },
  mounted() {
    this.getSubscriptionPlan();
    this.getBuildFaxPrice();
    this.RebindData();
  },
  created() {
    this.$store
      .dispatch("getpaymentservicetypes", this.companyID)
      .then((resolve) => {
        if (resolve.res == "0") {
          this.paymentservicetypes = resolve.data;
        } else {
          this.paymentservicetypes = [];
        }
      })
      .catch((e) => this.openGlobalSnackbar(e.message));
  },
  computed: {
    // eslint-disable-next-line
    mdchips(){
      let msg = '';
      if(this.features && !this.features.length){
        msg = this.plan_feature
      }
      return msg;
    },
    isLoading() {
      return this.$store.state.isLoading.payment;
    },
    userID: function () {
      var user = atob(atob(localStorage.getItem(btoa(btoa("permitUserID")))));
      return user;
    },
    SubscriptionPlan() {
      console.log(this.$store.state.subscriptionPlanList)
      return this.$store.state.subscriptionPlanList;
    },
    InternachiSubscriptionPlan() {
      console.log(this.$store.state.internachiSubscriptionList);
      return this.$store.state.internachiSubscriptionList;
    },
    paymentservices() {
      // eslint-disable-next-line
      if (this.$store.state.paymentservices && this.$store.state.paymentservices.length) {
        // eslint-disable-next-line
        for (var i = 0; i < this.$store.state.paymentservices.length; i++) {
          // eslint-disable-next-line
          if (this.$store.state.paymentservices[i].auth_json) {
            // eslint-disable-next-line
            var json = this.$store.state.paymentservices[i].auth_json;
            if (typeof json === "string") {
               json = JSON.parse(json);
            }
            // eslint-disable-next-line
            this.$store.state.paymentservices[i].auth_json = json;
            // eslint-disable-next-line 
            this.is_default = this.$store.state.paymentservices[i].is_default;
            // eslint-disable-next-line 
            this.company_payment_service_id = this.$store.state.paymentservices[i].company_payment_service_id;
            // if payment_service_id==2 then """AuthorizeNet"""
            // eslint-disable-next-line
            if (this.$store.state.paymentservices[i].payment_service_id === 2) {
              // eslint-disable-next-line
              this.$store.state.paymentservices[i].payment_service_type =
                "AuthorizeNet";
              // eslint-disable-next-line
              this.$store.state.paymentservices[i].Login_ID =
                this.$store.state.paymentservices[i].auth_json.name;
              // eslint-disable-next-line
              // this.PaymentServiceData.Login_ID = this.$store.state.paymentservices[i].auth_json.name;
              // eslint-disable-next-line
              this.$store.state.paymentservices[i].Transaction_Key =
                this.$store.state.paymentservices[i].auth_json.transactionKey;
              // eslint-disable-next-line
              // this.PaymentServiceData.Transaction_Key = this.$store.state.paymentservices[i].auth_json.transactionKey;
            }
            // if payment_service_id==4 then """Signature Card Payment"""
            // eslint-disable-next-line
            else if (
              this.$store.state.paymentservices[i].payment_service_id === 4
            ) {
              // eslint-disable-next-line
              this.PaymentServiceData.payment_service_id = 4;
              // eslint-disable-next-line
              this.$store.state.paymentservices[i].payment_service_type =
                "Signature Card";
              // eslint-disable-next-line
              this.$store.state.paymentservices[i].Merchant_User_Name =
                this.$store.state.paymentservices[
                  i
                ].auth_json.Merchant_User_Name;
              // eslint-disable-next-line
              // this.PaymentServiceData.Merchant_User_Name = this.$store.state.paymentservices[i].auth_json.Merchant_User_Name;
              // eslint-disable-next-line
              this.$store.state.paymentservices[i].Merchant_Password =
                this.$store.state.paymentservices[
                  i
                ].auth_json.Merchant_Password;
              // eslint-disable-next-line
                // this.PaymentServiceData.Merchant_Password = this.$store.state.paymentservices[i].auth_json.Merchant_Password;
              
            }
            else if (
              this.$store.state.paymentservices[i].payment_service_id === 7
            ) {
              // eslint-disable-next-line
              this.$store.state.paymentservices[i].payment_service_type =
                "FluidPay";
              // eslint-disable-next-line
              this.$store.state.paymentservices[i].apiKey =
                this.$store.state.paymentservices[i].auth_json.apiKey;
              // eslint-disable-next-line              
              // this.PaymentServiceData.apiKey = this.$store.state.paymentservices[i].auth_json.apiKey;
            }
          }
        }
      }
      // eslint-disable-next-line
      if (this.$store.state.paymentservices && this.$store.state.paymentservices.length) {
        // eslint-disable-next-line
        this.$store.state.paymentservices.map(
          (x) =>
            (x.company_payment_service_id = x.permit_default_payment_service_id)
        );
      }
      // eslint-disable-next-line
      return this.$store.state.paymentservices;
    },
  },
  methods: {
    ValidateSignatureCardService() {
      this.$validator.validateAll("Merchant").then((result) => {
        if (result) {
          let payload = {
            "payment_service_id": this.PaymentServiceData.payment_service_id,
            "auth_json": {
              "Merchant_User_Name": this.PaymentServiceData.Merchant_User_Name,
              "Merchant_Password": this.PaymentServiceData.Merchant_Password
            }
          }
          this.$store.dispatch('validatesignaturecardmerchant', payload)
            .then((response) => {
              if (response.res == "0") {
                var result = response.data;
                if (result) {
                  var PaymentGatwayResponse = {
                    "Merchant_User_Name": this.PaymentServiceData.Merchant_User_Name,
                    "Merchant_Password": this.PaymentServiceData.Merchant_Password
                  }
                  this.PaymentServiceData.payment_service_type = "Signature Card"
                  this.PaymentServiceData.isEditingMode = false;
                  this.SetVisibleSaveButton(PaymentGatwayResponse)
                } else {
                  this.openGlobalSnackbar(response.msg);
                }
              }
            }, this.errorCallback);
        }
        else{
          this.openGlobalSnackbar("please enter Merchant name & Merchant password!")
        }
      });
    },
    SetVisibleSaveButton(PaymentGatwayResponse) {
      this.PaymentGatwayResponse = PaymentGatwayResponse;
      this.SavePaymentService();
    },
    ChangeBtnVisibility() {
      this.isVisibleClose = false;
      this.isVisibleSave = false;
      this.isVisibleAdd = true;
    },
    callback(response) {
      if (response.res == "0") {
        this.ChangeBtnVisibility();
        this.RebindData();
      }
      this.openGlobalSnackbar(response.msg);
    },
    errorCallback: function (reject) {
      console.log(reject);
      this.openGlobalSnackbar(reject.msg);
    },
    close() {
      let service = this.paymentservices;
      let filterOptions = []
      filterOptions = service.filter(x => x.permit_default_payment_service_id === undefined);
      for (let i = 0; i < filterOptions.length; i++) {
        const index = this.paymentservices.indexOf(filterOptions[i]);
        if (index != -1)
          this.paymentservices.splice(index, 1)
      }
      this.ChangeBtnVisibility();
    },
    confirmDialog() {
      if (!this.is_default) {
        if (this.companyID==0) {
          this.openDialog('changePaymentService');
        }
      }
    },
    okDefaultPayemntService(ref) {
      this.closeDialog(ref);
      this.setDefaultPayemntService();
    },
    okDialog() {
      this.deletePaymentService();
      this.closeDialog('confirmdel');
    },
    deletePaymentService(){
      let service = this.paymentservices;
      let filterOptions = []
      filterOptions = service.filter(x => x.permit_default_payment_service_id === undefined);
      for (let i = 0; i < filterOptions.length; i++) {
        const index = this.paymentservices.indexOf(filterOptions[i]);
        if (index != -1)
          this.paymentservices.splice(index, 1)
      }
      this.ChangeBtnVisibility();
    },
    setDefaultPayemntService() {
      let api = '';
      api = 'permitDefaultPaymentService';
      this.$store.dispatch(api, {
        company_payment_service_id: this.company_payment_service_id,
        companyId: this.companyID,
        is_from_contractor: 0,
        user_id: this.userId
      })
        .then((response)=> {
          if (response.res == "0") {
            this.openGlobalSnackbar(response.msg);
            this.RebindData();
          }
        }, this.errorCallback);
    },
    SavePaymentService: function () {
      var newPaymentService = this.paymentservices.filter(function (pay) {
        return (pay.IsAddingNew == true)
      });
      var payment_service_id = 0;
      if (newPaymentService != null && newPaymentService.length > 0) {
        payment_service_id = newPaymentService[0].payment_service_id;
      }
      if ([1,2,3,4,7].includes(payment_service_id)) {
        let para = {
          payment_service_id: payment_service_id,
          auth_json: JSON.stringify(this.PaymentGatwayResponse)
        }
        let api = '';
        api = 'permitPaymentService';
        this.$store.dispatch(api, {
          companyId: this.companyId,
          data: para
        })
          .then((response)=> {
            this.callback(response);
            setTimeout(() => {
              if (this.$store.state.paymentservices && this.$store.state.paymentservices.length == 1) {
                show();
                if (!this.$store.state.paymentservices[0].is_default) {             
                  this.$store.dispatch('setdefaultcompanypaymentservice',{
                    company_payment_service_id: this.$store.state.paymentservices[0].company_payment_service_id,
                    companyId: this.companyId,
                    is_from_contractor: 0,
                    user_id: this.userID
                  })
                  .then(function (response) {
                    if (response.res == "0") {
                      this.openGlobalSnackbar(response.msg)
                      this.RebindData()
                      hide();
                    }
                  }, this.errorCallback);
                }
              }
            }, 1000);

          }, this.errorCallback);
      }

    },
    ValidateAuthorizeNetService() {
      this.$validator.validateAll("Authorize").then(result => {
        if (result) {
          let para = {
            "authenticateTestRequest": {
              "merchantAuthentication": {
                "name": this.PaymentServiceData.Login_ID,
                "transactionKey": this.PaymentServiceData.Transaction_Key
              }
            }
          }
          this.$store.dispatch('validateauthorizenetservice', para)
            .then(response => {
              if (response.res == "0"/* successfully validated*/) {
                var PaymentGatwayResponse = {
                  "name": this.PaymentServiceData.Login_ID,
                  "transactionKey": this.PaymentServiceData.Transaction_Key
                }
                this.PaymentServiceData.payment_service_type = "Authorize.net"
                this.PaymentServiceData.isEditingMode = false;
                this.SetVisibleSaveButton(PaymentGatwayResponse)
              }
              this.openGlobalSnackbar(response.msg);
            }, this.errorCallback);
        }
        else{
          this.openGlobalSnackbar("please enter Login ID and Transaction Key!")
        }
      });
    },
    ValidateFluidPay() {
      let that = this;
      this.$validator.validateAll("fluidpay").then(result => {
        if (result) {
          let payload = {           
            "auth_json": {
              "apiKey": this.PaymentServiceData.apiKey,
            }
          }
          that.$store.dispatch('ValidateFluidPay', payload)
            .then((response) => {
              if (response.res == "0") {
                var result = response.data;
                if (result) {
                  var PaymentGatwayResponse = {
                    apiKey: this.PaymentServiceData.apiKey
                  }
                  this.PaymentServiceData.payment_service_type = "FluidPay"
                  this.PaymentServiceData.isEditingMode = false;
                  this.SetVisibleSaveButton(PaymentGatwayResponse)
                } 
                else {
                  this.openGlobalSnackbar(response.msg);
                }
              }
              else {
                this.openGlobalSnackbar(response.msg);
              }
            }, this.errorCallback);
        }
        else{
          this.openGlobalSnackbar("Please enter Api key!")
        }
      });
    },
    getBuildFaxPrice() {
      this.$store
        .dispatch("getAndEditPermitchekPrice")
        .then((response) => {
          if (response.res == 0) {
            this.system_configration_id = response.data.system_configration_id;
            this.permitchek_price = response.data.buildfax_price;
            this.internachiSingleReportPrice = response.data.internachi_buildfax_price;
          }
        })
        .catch((e) => this.openGlobalSnackbar(e.message));
    },
    // eslint-disable-next-line
    openDialog(ref,permit_default_payment_service_id) {
      this.disablescrolling()
      let index = this.openedDialog.indexOf(ref)
      if (index == -1) this.openedDialog.push(ref);
      this.$validator.validateAll().then(res => {
        if(res == false){
          this.$validator.reset();
          this.$validator.errors.items=[];
        }
      })
      this.company_payment_service_id = permit_default_payment_service_id;
      this.amount =   this.singleReportType == 'internachi' ? this.internachiSingleReportPrice: this.permitchek_price 
      setTimeout(()=>{
        if(this.$refs && this.$refs[ref]){
          this.$refs[ref].open();
        }
      },100);
      
    },
    closeDialog(ref) {
      this.singleReportType = '';
      // this.blankObj={};
      // this.resetBlankObj();
      this.enablescrolling();
      this.plan_feature='';
      this.$refs[ref].close();
      let index = this.openedDialog.indexOf(ref);
      if (index != -1) this.openedDialog.splice(index, 1);
    },
    updateBuildFaxPrice() {
      this.$validator.validateAll("EditPermitchek").then((result) => {
        if (result) {
          let payload = {
            // updated_price: this.amount,
            system_configration_id: this.system_configration_id,
          };
          if(this.singleReportType == 'internachi') {
            payload.updated_internachi_price = this.amount;
            payload.updated_price = this.permitchek_price;
          } else {
            payload.updated_price = this.amount;
            payload.updated_internachi_price = this.internachiSingleReportPrice;
          }
          this.$store
            .dispatch("updatePermitCheckPrice", payload)
            .then((response) => {
              this.openGlobalSnackbar(response.msg);
              if (response.res == 0) {
                this.closeDialog("add-permitchek-plan");
                this.getBuildFaxPrice();
                this.singleReportType = '';
              }
            })
            .catch((e) => this.openGlobalSnackbar(e.message));
        }
      });
    },

    // Subscription plan
    getSubscriptionPlan() {
      this.$store.dispatch("getPermitSubscriptions").then((response) => {
        const internachiPlans = response.data.internachi_plan;
        if (response.res == 0) {
          response.data = response.data.normal_plans.map((x) => {
            let o = Object.assign({}, x);
            o.subscription_id = x.permit_subscription_id;
            return o;
          });
          const internachiResponse = {
            data: [],
          }
   
          internachiResponse.data = internachiPlans.map((x) => {
            let o = Object.assign({}, x);
            o.subscription_id = x.permit_subscription_id;
            return o;
          });
          this.$store.commit("GET_SUBSCRIPTION_LIST", response);
          this.$store.commit("GET_INTERNACHI_SUBSCRIPTION_LIST", internachiResponse);
        }
      });
    },
    openEditAddDialog(ref, id) {
      console.log(id, this.addSubscriptionPlanType)
      if(this.addSubscriptionPlanType == 'internachi') {
      this.dynamickey++;
      if (id == 0) {
        this.resetBlankObj();
        this.resetFeatures();
      } else {
        let obj = this.InternachiSubscriptionPlan.find((x) => x.subscription_id == id);
        if (obj) {
          let newObj = Object.assign({}, obj);
          newObj.currency_symbol = "$";
          newObj.price = newObj.price
            ? newObj.price.toString().replace(/[^0-9.]/gi, "")
            : newObj.price;
          newObj.user_id = this.userID;
          newObj.subscription_frequency = newObj.subscription_frequency
            ? newObj.subscription_frequency
            : newObj.validity_in_days.toString();
          this.blankObj = newObj;
          this.blankObj.is_visible = this.blankObj.is_visible ? true : false;
          let featur = newObj.features.split("<&&>");
          this.features = [];
          for (var i = 0; i < featur.length; i++) {
            this.features.push(featur[i]);
          }
        }
      }
      this.openDialog(ref);
    }

      this.dynamickey++;
      if (id == 0) {
        this.resetBlankObj();
        this.resetFeatures();
      } else {
        let obj = this.SubscriptionPlan.find((x) => x.subscription_id == id);
        if (obj) {
          let newObj = Object.assign({}, obj);
          newObj.currency_symbol = "$";
          newObj.price = newObj.price
            ? newObj.price.toString().replace(/[^0-9.]/gi, "")
            : newObj.price;
          newObj.user_id = this.userID;
          newObj.subscription_frequency = newObj.subscription_frequency
            ? newObj.subscription_frequency
            : newObj.validity_in_days.toString();
          this.blankObj = newObj;
          this.blankObj.is_visible = this.blankObj.is_visible ? true : false;
          let featur = newObj.features.split("<&&>");
          this.features = [];
          for (let i = 0; i < featur.length; i++) {
            this.features.push(featur[i]);
          }
        }
      }
      this.openDialog(ref);
    },
    saveSubscriptionPlan(ref) {
      if(this.features && !this.features.length){
        this.plan_feature = "The Plan Feature field is required";
      }
      else{
        this.plan_feature = '';
      }

      this.$validator.validateAll("subscription").then((result) => {
        if (result) {
          let obj = Object.assign({}, this.blankObj);
          obj.features = "";
          if (this.features.length) obj.features = this.features.join("<&&>");
          obj.validity_in_days = obj.subscription_frequency;
          let payload = obj;
          payload.allowed_devices = payload.allowed_devices
            ? payload.allowed_devices
            : 0;
          payload.permit_subscription_id = obj.subscription_id;
          // payload.permit_subscription_id = obj.subscription_id !== 0 ? obj.subscription_id : undefined;
          payload.user_id = this.userID;
          console.log(payload);
          this.$store
            .dispatch("addUpdatePermitSubscription", { payload })
            .then((response) => {
              console.log(response, ref);
              this.addSubscriptionPlanType ='';
              this.openGlobalSnackbar(response.msg);
              if(response.res == 0) {
                const normalPlans =  { data:response.data.normal_plans };
                const internachiPlans = {data: response.data.internachi_plan };
                console.log(normalPlans, internachiPlans);
                normalPlans.data = normalPlans.data.map((x) => {
                    x.subscription_id = x.permit_subscription_id;
                    return x;
                  });

                  internachiPlans.data = internachiPlans.data.map((x) => {
                    x.subscription_id = x.permit_subscription_id;
                    return x;
                  });

                  console.log(normalPlans, internachiPlans);
                
                  this.$store.commit("ADD_UPDATE_SUBSCRIPTION", normalPlans);
                  this.$store.commit("ADD_UPDATE_INTERNACHI_SUBSCRIPTION", internachiPlans);
                  this.closeDialog(ref);
              }
              // if (response.res == 0) {
              //   const internachiPlansResponse = {
              //     data: []
              //   }
              // console.log(response);
              // internachiPlansResponse.data = response.data.internachi_plan;
              //   response.data = response.data.normal_plans.map(
              //     (x) => (x.subscription_id = x.permit_subscription_id)
              //   );
    
              //   internachiPlansResponse.data = response.data.internachi_plan.map(
              //     (x) => (x.subscription_id = x.permit_subscription_id)
              //   );
              //   this.$store.commit("ADD_UPDATE_SUBSCRIPTION", response);
              //   this.$store.commit("ADD_UPDATE_INTERNACHI_SUBSCRIPTION", internachiPlansResponse);
              //   this.closeDialog(ref);
              // }
            });
        }
      });
    },
    resetBlankObj() {
      this.blankObj.subscription_id = 0;
      this.blankObj.title = "";
      this.blankObj.price = "";
      this.blankObj.report_limit = "";
      this.blankObj.charge_per_report = "";
      this.blankObj.validity_in_days = "";
      this.blankObj.subscription_frequency = "";
      this.blankObj.features = "";
      this.blankObj.allowed_devices = "";
      this.blankObj.currency_symbol = "$";
      this.blankObj.plan_type = "";
      this.blankObj.is_system = 0;
      this.blankObj.allow_qty = false;
      this.blankObj.user_id = this.userID;
      this.blankObj.is_visible = true;
      this.dynamickey++;
      if(this.addSubscriptionPlanType == 'internachi') {
        this.blankObj.is_internachi = 1
      } else if (this.addSubscriptionPlanType == 'normal') {
        this.blankObj.is_internachi = 0
      } else {
        this.blankObj.is_internachi = null;
      }
    },
    resetFeatures() {
      this.features = [];
    },
    openConfirmDailog(ref, type, id) {
      this.selectedSubscription = id;
      this.is_active = type;
      if (type == 1) {
        this.confirmMessage =
          "Are you sure you want to deactive subscription ?";
      } else {
        this.confirmMessage = "Are you sure you want to active subscription ?";
      }
      this.openDialog(ref);
    },
    okChangeStatus(ref) {
      this.changeStatus();
      this.closeDialog(ref);
    },
    changeStatus() {
      let SubscriptionId = this.selectedSubscription;
      let payload = {
        is_active: this.is_active == 0 ? 1 : 0,
        permit_subscription_id: SubscriptionId,
      };
      this.$store
        .dispatch("changePermitSubscriptionStatus", payload)
        .then((response) => {
          let obj = {
            SubscriptionId: SubscriptionId,
            is_active: payload.is_active,
          };
          this.$store.commit("CHANGE_SUBSCRIPTION_STATUS", obj);
          this.openGlobalSnackbar(response.msg);
        });
    },
    openConfirmDeleteDailog(ref, id) {
      this.selectedSubscription = id;
      this.confirmMessage = "Are you sure you want to delete subscription ?";
      this.openDialog(ref);
    },
    okDelete(ref) {
      this.deleteSubscription();
      this.closeDialog(ref);
    },
    deleteSubscription() {
      let SubscriptionId = this.selectedSubscription;
      console.log(SubscriptionId);
      this.$store
        .dispatch("deletePermitSubscription", SubscriptionId)
        .then((response) => {
          let obj = {
            SubscriptionId: SubscriptionId,
          };
          this.$store.commit("DELETE_SUBSCRIPTION", obj);
          this.openGlobalSnackbar(response.msg);
        });
    },
    // payment
    addNewPayment() {
      if (this.paymentservicetypes && this.paymentservicetypes.length) {
        this.updated_paymentservicetypes = this.paymentservicetypes;
      }
      this.isVisibleClose = true;
      this.isVisibleSave = false;
      this.isVisibleAdd = false;
      let obj = {
        id: this.id++,
        publishable_key: "",
        payment_service_id: "",
        IsVisibleStripeBtn: false,
        payment_service_type: "",
        isEditMode: true,
        IsAddingNew: true,
      };
      this.$store.commit("ADD_NEW_PAYMENT_SERVICE", obj);
    },
    RebindData() {
      this.$store
        .dispatch("getPermitPaymentService")
        .then((response) => {
          if (response.res == 0) {
            this.$store.commit("STOP_LOADING", "payment");
            this.$store.commit("PAYMENT_SERVICES", response);
          } else {
            this.openGlobalSnackbar(response.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>