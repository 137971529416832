<template>
  <div class="full-container" ref="cool">
    <div class="full-container-panel">
      <div class="columns transactions-header width-100">
        <div class="column is-2 inline-block panel-collapse-header-text width-20">
          <span class="t4-pixel">Send Email: </span>
        </div>
        <div class="column is-10 float-right width-80 penal-hd">
          <div class="columns inline-flex transaction-filter" style="align-items: center">
            <div class="column is-3 m-r">
              <!-- Placeholder for additional content if needed -->
            </div>
          </div>
        </div>
      </div>
      <!-- Main Email Content -->
      <div class="panel-block">
        <div class="input-container">
          <vue-tags-input class="vue-tags-input ml-10" v-model="to_email_address" 
            :placeholder=" areAllUserSelected ? 'You have selcted all users' :  'TO (Please select Users)'" 
            
            :tags="to_array_list"
            :autocomplete-items="autocompleteItems" adding-duplicate="true" :validation="validation"

            @before-deleting-tag = "deletedTag"
            :class="{ 'not-allowed': !to_array_list.length && !autocompleteItems.length }"
            :readonly="!to_array_list.length && !autocompleteItems.length"
            />
          <div class="button-container">
            <span class="button" @click="showContactList('to')">Select Users</span>
          </div>
        </div>
      </div>
      <!-- <hr> -->
      <div class="panel-block ml-10 p-l-10">
        <div class="input-container">
          <input type="text" v-model="emailSubject" class="subject-input" placeholder="Subject">
        </div>
      </div> 
      <div>
        <vue-editor v-model="content" :editor-toolbar="customToolbar"></vue-editor>
      </div>
    </div>
    <!-- <Attachments /> -->
    <user-modal ref="user-modal" @selectedUsers ="selectedUsersToSendEmail" 
    :allSelected="areAllUserSelected" 
    :selectedUsers="to_array_list"
    />
    <button @click="sendEmail" class="button send-email">Send Email</button>

    <!-- <ckeditor :editor="editor" v-model="editorData" :config="editorConfig"></ckeditor> -->

  </div>
</template>

<script>
import VueTagsInput from '@johmun/vue-tags-input';
import UserModal from './UserModal2.vue';
import { VueEditor } from "vue2-editor";
// import Attachments from './Attachments.vue';

// import CKEditor from '@ckeditor/ckeditor5-vue2';
// import { ClassicEditor, Bold, Essentials, Italic, Mention, 
//   Paragraph, Undo, Font, Heading, Underline, Subscript, Superscript, Link, 
//   List, Indent, IndentBlock, BlockQuote, Image, ImageToolbar, ImageUpload
//   ,SimpleUploadAdapter
//  } from 'ckeditor5';

import 'ckeditor5/ckeditor5.css';

export default {
  name: 'sendEmail',
  components: {
    VueTagsInput,
    // ckeditor: CKEditor.component,
    VueEditor,
    UserModal,
    // Attachments
  },
  data() {
    return {
      to_email_address: '',
      emailSubject: '',
      to_array_list: [],
      validation: [{
        classes: 'error',
        rule: /^(\s?[^\s,]+@[^\s,]+\.[^\s,]+\s?,)*(\s?[^\s,]+@[^\s,]+\.[^\s,]+)$/,
        disableAdd: true,
      }],
      autocompleteItems: [],
      content: "",
      userIds: [],
      areAllUserSelected: false,
      customToolbar: [
        // ['undo', 'redo'],
        // [{ 'size': ['small', false, 'large', 'huge'] }],
      ],
      // editor: ClassicEditor,
      editorData: '<p>Hello from CKEditor 5 in Vue 2!</p>',
      editorConfig: {
        toolbar: {
          items: [ 'undo', 'redo', '|', 'bold', 'italic', 
            'fontSize', 'fontFamily', 'fontColor', 'fontBackgroundColor', 
            'heading', 'underline', 'subscript', 'superscript', 'link',
            '|', 'numberedList', 'bulletedList', 'indent', 'indentBlock', 'blockQuote',
            'imageUpload'
          ],
        },
        upload: {
          // Configure the URL to which the image is uploaded
          url: 'https://your-image-upload-endpoint.com',
          headers: {
            // Configure the headers for the upload request
            'X-CSRF-TOKEN': 'CSRF-Token',
            Authorization: 'Bearer <JSON Web Token>'
          }
        },
        // plugins: [
        //   Bold, Essentials, Italic, Mention, Paragraph, Undo, Font, Heading, Underline,
        //   Subscript, Superscript, Link, List, Indent, IndentBlock, BlockQuote, Image, 
        //   ImageToolbar, ImageUpload, SimpleUploadAdapter
        // ],
      }
    }
  },
  mounted() {
    // Quill.register({
    //   'modules/undo': Undo,
    //   'modules/redo': Redo
    // }, true);
  },
  methods: {
    updateTo(newTags) {
      this.autocompleteItems = [];
      this.to_array_list = newTags;
    },
    deletedTag(tag) {
      this.to_array_list.splice(tag.index, 1);
      // this.$refs['user-modal'].removeUserFromList(tag);
    },
    showContactList() {
      // console.log(val);
      this.openDialog('addContactEmail');
    },
    openDialog() {
      this.$refs['user-modal'].$refs.addContactEmail.open()
    },
    selectedUsersToSendEmail(users) {
      
      if ( Array.isArray(users) && users.length > 0) {
        // this.to_array_list = [...this.to_array_list, ...users]
        
        const existingUserIds = this.to_array_list.map(user => user.user_id);

        const newUsers = users.filter(user => !existingUserIds.includes(user.user_id)).map(user => ({
          ...user,
          text: `${user.first_name} ${user.last_name}`
        }));

        this.to_array_list = [...this.to_array_list, ...newUsers];

        this.areAllUserSelected = false;
      } else {
        this.areAllUserSelected = true;
        this.to_array_list = [];
      }
    },
    sendEmail() {
      console.log(this.to_array_list);
      this.userIds = this.to_array_list.map((el) => el.user_id);
      // console.log(this.userIds)
      const payload = {
        user_ids: this.userIds,
        subject: this.emailSubject,
        content: this.content,
        send_to: this.areAllUserSelected ? "all": "specific",
      }
     
      if(!this.emailSubject) {
        this.openGlobalSnackbar('Please add Subject!');
        return;
      }

      if(!this.content) {
        this.openGlobalSnackbar('Please add Content!');
        return;
      }
     
      this.to_array_list = [];
      this.emailSubject = '';
      this.content = '';
      this.$refs['user-modal'].clearData()
      this.$store
        .dispatch("sendEmail", {
          ...payload,
        })
        .then((response) => {
          if (response && response.res == 1) {
            this.openGlobalSnackbar(response.msg);
          }
      });
    }
  }
};
</script>

<style scoped>
.panel-block {
  display: flex;
  width: 100%;
}

.input-container {
  display: flex;
  align-items: center;
  width: 100%;
}

.vue-tags-input {
  flex: 1;
  max-width: 80%; /* Set max width to 80% */
  padding: 20px 0px 10px 10px;
}

.vue-tags-input.not-allowed {
    cursor: not-allowed; /* Change cursor on hover */
    opacity: 0.6; /* Reduce opacity to indicate disabled state */
  }

  .vue-tags-input[readonly] {
    cursor: not-allowed; /* Change cursor for readonly state */
  }


.ml-10 {
  margin-left: 10px;
}

.subject-input {
    width: 80%; /* Adjust width as needed */
    border: 1px solid #d2cccc; /* Default border color */
    padding: 10px; /* Padding for input */
    padding-left: 10px; /* Additional left padding */
    margin-bottom: 15px; /* Margin bottom for spacing */
    outline: none; /* Remove outline on focus */
    transition: border-color 0.2s ease-in-out; /* Smooth transition for border color */
  }
.subject-input:focus {
    border-color: rgb(146, 143, 143) !important; /* Change border color to green on focus */
  }

.button-container {
  margin-left: 10px;
  flex-shrink: 0;
}

.button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  text-align: center;
  white-space: nowrap;
}

.button:hover {
  background-color: #0056b3;
}

ql-editor p {
  color: inherit;
  background: red;
}

.send-email {
  margin-top: 15px;
}


</style>
