<template>
  <div class="material-design-icon" id="checkbox-marked-icon" role="img" aria-labelledby="checkbox-marked-icon-title">
    <svg class="material-design-icon__svg" width="24" height="24" viewBox="0 0 24 24">
      <path d="M10,17L5,12L6.41,10.58L10,14.17L17.59,6.58L19,8M19,3H5C3.89,3 3,3.89 3,5V19C3,20.1 3.9,21 5,21H19C20.1,21 21,20.1 21,19V5C21,3.89 20.1,3 19,3Z"></path>
    </svg>
    <md-tooltip style="background-color: black;" md-direction="bottom" class="common-tooltip">{{iconTitle}}</md-tooltip>
  </div>
</template>

<script>
  export default {
    name: "checkbox-marked-icon",
    data () {
      let iconTitle = this.title ? this.title : "Checkbox marked icon"

      return {
        iconTitle: iconTitle
      }
    },
    props: {
      title: {
        type: String
      }
    }
  }
</script>
