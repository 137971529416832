<style scoped>
::-webkit-input-placeholder {
  font-size: 16px;
}
::-moz-placeholder {
  font-size: 16px;
}
:-ms-input-placeholder {
  font-size: 16px;
}
::placeholder {
  color: #000000;
  font-size: 16px;
}
</style>

<template>
  <md-input
    type="text"
    :placeholder="label ? label : 'Click here to select a date'"
    readonly
  ></md-input>
</template>

<script>
import moment from "moment";
// let self;
export default {
  name: "DateRange",
  props: ["dateFormat", "startDate", "endDate", "label"],
  data: function () {
    // self = this;
    return {
      start: this.startDate ? this.startDate : moment(),
      end: this.endDate ? this.endDate : moment().add(2, "days"),
    };
  },
  mounted: function () {
    // eslint-disable-next-line no-unused-vars
    const self = this
        // eslint-disable-next-line no-undef
        $(this.$el).daterangepicker({
          autoUpdateInput: this.endDate && this.endDate ? true : false,
          locale: {
            format: this.dateFormat,
            cancelLabel: "Clear",
          },
          startDate: this.start,
          endDate: this.end,
          ranges: {
            Today: [moment(), moment()],
            Tomorrow: [moment().add(1, "days"), moment().add(1, "days")],
            Yesterday: [
              moment().subtract(1, "days"),
              moment().subtract(1, "days"),
            ],
            "Last Week": [
              moment().subtract(1, "week").startOf("week"),
              moment().subtract(1, "week").endOf("week"),
            ],
            "This Week": [moment().startOf("week"), moment().endOf("week")],
            "Next Week": [
              moment().add(1, "week").startOf("week"),
              moment().add(1, "week").endOf("week"),
            ],
            "This Month": [
              moment().startOf("month").startOf("month"),
              moment().endOf("month"),
            ],
            "Last Month": [
              moment().subtract(1, "month").startOf("month"),
              moment().subtract(1, "month").endOf("month"),
            ],
            "Next Month": [
              moment().add(1, "month").startOf("month"),
              moment().add(1, "month").endOf("month"),
            ],
            "This Year": [
              moment().startOf("year").startOf("year"),
              moment().endOf("year"),
            ],
            "Last Year": [
              moment().subtract(1, "year").startOf("year"),
              moment().subtract(1, "year").endOf("year"),
            ],
          },
        },this.cb);

    if (this.startDate && this.endDate) {
      this.cb(this.start, this.end);
    }

    // eslint-disable-next-line no-undef
    $(this.$el).on("apply.daterangepicker", function (data) {
      let value = data.target.value;
      self.$emit("update-date", value);
    });
    // eslint-disable-next-line no-undef
    $(this.$el).on("cancel.daterangepicker", function (ev, picker) {
      this.start = moment();
      this.end = moment().add(2, "days");
      picker.element[0].value = "";
      let value = "";
      self.$emit("clear-date", value);
    });
  },
  methods: {
    cb(start, end) {
        if (start && end) {
          // eslint-disable-next-line no-undef
          $(this.$el).val(
            start.format(this.dateFormat) + " - " + end.format(this.dateFormat)
          );
        } else {
          // eslint-disable-next-line no-undef
          $(this.$el).val("");
        }
    },
  },
  beforeDestroy: function () {
                // eslint-disable-next-line no-undef
                $(this.$el).daterangepicker("hide").daterangepicker("destroy");
                let elements = document.getElementsByClassName("daterangepicker");
                while (elements.length > 0) {
                elements[0].parentNode.removeChild(elements[0]);
            }
  },
};
</script>
