import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueMaterial from 'vue-material'
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import icon from './components/icon/icon'
import filter from './filter/index'
import Common from './common-plugin/common-plugin'
import VeeValidate,{ Validator } from 'vee-validate'

const options = {
  confirmButtonColor: '#275F7C',
  cancelButtonColor: '#ff7674',
};
// Vue.use(require('vue-moment'));


Vue.config.warnHandler = function(msg) {
  if (msg !== "The .native modifier for v-on is only valid on components but it was used on <tr>.") {
    console.error(msg)
  }  
}
const dictionary = {
  en: {
    messages:{
      digits: (field, [length]) => `Please enter exactly ${length} digits.`
    }
  },
};
Vue.use(VeeValidate)
Validator.localize(dictionary);
Vue.use(VueMaterial)
Vue.use(VueSweetalert2,options);
Vue.use(Common);

Vue.config.productionTip = false

new Vue({
  filter,
  icon,
  router,
  store,
  render: h => h(App),
}).$mount('#app')
