<template>
  <div class="full-container">
    <div class="full-container-panel">
      <div class="columns transactions-header width-100">
        <div class="column is-2 inline-block panel-collapse-header-text" style="width:25%">
          <span class="t4-pixel">Buildchek Transaction</span>
        </div>
        <div class="column is-10 float-right penal-hd" style="width:75%">
          <div class="columns inline-flex transaction-filter">
            <div class="column is-3 m-r datepicker placeholderColor line-white width-210 input-focus">
              <md-input-container class="theme-white">
                  <DateRange :startDate="start" :endDate="end"
                    class="md-select choices md-theme-default custom_datepicker" @update-date="updateDate"
                    @clear-date="clearDate" dateFormat="MM-DD-YYYY">
                  </DateRange>
                </md-input-container>
            </div>
            <div class="column is-3 m-r">
              <div class="width-210 line-white search-color-input input-focus">
                <md-input-container class="theme-white">
                  <md-icon>
                    <magnify-icon title="Search" />
                  </md-icon>
                  <md-input
                    placeholder="Search Request"
                    v-model.trim="search"
                    @input="searchData"
                    type="text"
                  >
                  </md-input>
                  <span
                    class="md-icon md-theme-white material-icons"
                    @click="clearSearch"
                  >
                    <md-icon>
                      <close-icon :title="tipclear" />
                    </md-icon>
                  </span>
                </md-input-container>
              </div>
            </div>
            <div class="column is-3 m-r">
              <div class="width-210 line-white filter-type-after">
                <md-input-container>
                  <label class="color-white">Filter by Type </label>
                  <md-select
                    placeholder="Filter by type"
                    name="company"
                    v-model="search_by_type"
                    class="choices p-t-5"
                    @change="searchData"
                  >
                    <md-option value="" selected>No filter</md-option>
                    <md-option value="Permit">Permit</md-option>
                    <md-option value="Subscription">Subscription</md-option>
                  </md-select>
                </md-input-container>
              </div>
            </div>
            <div class="column is-3 m-r">
              <div class="width-210 line-white filter-type-after">
                <md-input-container>
                  <label class="color-white">Filter by Status </label>
                  <md-select
                    placeholder="Filter by Status"
                    name="company"
                    v-model="search_status"
                    class="choices p-t-5"
                    @change="searchData"
                  >
                    <md-option value="" selected>No filter</md-option>
                    <md-option value="Success">Success</md-option>
                    <md-option value="Failed">Fail</md-option>
                  </md-select>
                </md-input-container>
              </div>
            </div>
          </div>
        </div>
      </div>

      <md-table class="width-100">
        <md-table-header v-if="permitTransactionListing.length">
          <md-table-row>
            <md-table-head>User name</md-table-head>
            <md-table-head md-numeric>Created Date</md-table-head>
            <md-table-head md-numeric>Transaction Type</md-table-head>
            <md-table-head md-numeric>Property Address</md-table-head>
            <md-table-head md-numeric>Total Buildchek</md-table-head>
            <md-table-head md-numeric>Report</md-table-head>
            <md-table-head md-numeric>Status</md-table-head>
            <md-table-head md-numeric>Payment</md-table-head>
          </md-table-row>
        </md-table-header>

        <md-table-body v-if="permitTransactionListing.length">
          <md-table-row
            v-for="row in permitTransactionListing"
            :key="row.permit_transaction_id"
          >
            <md-table-cell>
              <template v-if="row.permit_user">
                {{
                  row.permit_user.first_name + " " + row.permit_user.last_name
                }}
              </template>
              <template v-else>
                <span>-</span>
              </template>
            </md-table-cell>

            <md-table-cell>
              <span>
                {{ row.created_date | FormatFullDateToMMDD }}
              </span>
            </md-table-cell>

            <md-table-cell>
              <span v-if="row.transaction_type">
                {{ row.transaction_type }}
              </span>
            </md-table-cell>

            <md-table-cell>
              <span v-if="row.permit_property">
                {{ row.permit_property.address }}
              </span>
              <span v-else>-</span>
            </md-table-cell>

            <md-table-cell>
              <span v-if="row.permit_property">
                {{ row.permit_property.total_permit }}
              </span>
              <span v-else>-</span>
            </md-table-cell>

            <md-table-cell>
              <a
                class="anchor-link"
                v-if="row.permit_property"
                :href="row.permit_property.report_link"
                target="_blank"
              >
              Buildchek Report</a
              >
              <span v-else>-</span>
            </md-table-cell>

            <md-table-cell>
              <span class="text-green" v-if="row.is_success">Success</span>
              <span class="has-text-danger" v-else>Failed</span>
            </md-table-cell>

            <md-table-cell>
              <p
                style="color: black"
                v-if="row.api_type == 'RETURN'"
                class="has-text-danger is-size-7"
              >
                <b>-{{ row.amount | ToUSDCurrencyFormat }} (Refunded)</b>
              </p>
              <p
                v-else-if="!row.is_success"
                class="has-text-danger"
                style="color: black"
              >
                <b>{{ row.amount | ToUSDCurrencyFormat }}</b>
              </p>
              <p v-else style="color: black">
                {{ row.amount | ToUSDCurrencyFormat }}
                <i
                  class="fa-solid fa-circle-info fa-fa-icon"
                  v-if="row.user_subscription && row.user_subscription.title"
                >
                  <md-tooltip style="background-color: black;"
                    md-direction="bottom"
                    class="common-tooltip multi-line"
                  >
                    {{ row.user_subscription.title }}</md-tooltip
                  >
                </i>
                <i
                  class="fa-solid fa-circle-info fa-fa-icon"
                  v-else-if="
                    row.permit_user_subscription &&
                    row.permit_user_subscription.title
                  "
                >
                  <md-tooltip style="background-color: black;"
                    md-direction="bottom"
                    class="common-tooltip multi-line"
                  >
                    {{ row.permit_user_subscription.title }}</md-tooltip
                  >
                </i>
              </p>
            </md-table-cell>
          </md-table-row>
        </md-table-body>

        <md-table-body v-if="permitTransactionListing.length == 0">
          <md-table-cell class="no-data"
            >No Buildchek transaction available.</md-table-cell
          >
        </md-table-body>
      </md-table>

      <div
        class="text-align-center"
        v-show="permitTransactionListing.length > 0"
      >
        <paginate
          ref="refPagintation"
          :page-count="numberOfPage"
          :prev-text="prev"
          :next-text="next"
          :container-class="pagination"
          :click-handler="clickCallback"
          :initial-page="0"
        >
        </paginate>
      </div>

      <div v-show="permitTransactionListing.length" class="d-flex t-history">
        <div class="has-text-left width-100">
          Total results {{ $store.state.total_permitTransaction }}
        </div>
        <div class="has-text-right width-100">
          <b>
            Total Amount: {{ total_transactionAmount | ToUSDCurrencyFormat }}</b
          >
        </div>
      </div>

      <div
        v-show="permitTransactionListing.length"
        class="d-flex"
        style="padding-right: 15px"
      >
        <div class="has-text-danger has-text-right text-size-13px width-100">
          <b>Total Failed Amount: - {{ total_fail | ToUSDCurrencyFormat }}</b>
        </div>
      </div>

      <div
        v-show="permitTransactionListing.length"
        class="d-flex"
        style="padding-right: 15px"
      >
        <div class="has-text-danger has-text-right text-size-13px width-100">
          <b>Total Refund: - ${{ total_refund }}</b>
        </div>
      </div>

      <div
        v-show="permitTransactionListing.length"
        class="d-flex t-history"
        style="padding-bottom: 20px"
      >
        <div class="has-text-right width-100">
          <b>Net Amount: {{ net_amount | ToUSDCurrencyFormat }}</b>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import Paginate from "vuejs-paginate";
import DateRange from '../dateRange/Daterangenew.vue'
export default {
  name: "transection-History",
  components: {
    Paginate,
    DateRange,
  },
  data() {
    return {
      PageRecord: 10,
      numberOfPage: 0,
      prev: "Prev",
      next: "Next",
      pagination: "pagination",
      PageNo: 1,
      RecordsPerPage: 10,
      showLoader: "none",

      tipclear: "Clear Search",
      search: "",
      start: moment().startOf("week"),
      end: moment().endOf("week"),
      search_status: "",
      search_by_type: "",
      start_date: "",
      end_date: "",
      searchInterval: null,

      total_fail: 0,
      total_refund: 0,
      total_transactionAmount: 0,
      finalTotalAmount: 0,
      net_amount: 0,
    };
  },
  computed: {
    permitTransactionListing() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.numberOfPage = Math.ceil(
        this.$store.state.total_permitTransaction / this.PageRecord
      );
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.total_fail = this.$store.state.total_fail;
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.total_refund = this.$store.state.total_refund;
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.total_transactionAmount = this.$store.state.total_transactionAmount;
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.finalTotalAmount = (
        this.total_transactionAmount - this.total_fail
      ).toFixed(2);
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.net_amount = (this.finalTotalAmount - this.total_refund).toFixed(2);
      return this.$store.state.permitTransactionListing.map((x) => {
        x.property_address = "";
        if (x.inspection && x.inspection.property) {
          let property = x.inspection.property;
          let stateName =
            property && property.state && property.state.state_name
              ? property.state.state_name
              : "";
          let countryName =
            property && property.country && property.country.country_name
              ? property.country.country_name
              : "";
          x.property_address = this.generateAddress(
            this.generateAddressLine(
              property.address_line1,
              property.address_line2
            ),
            property.city,
            this.generateAddressLine(stateName, property.zip_code),
            countryName
          );
        }
        return x;
      });
    },
  },
  mounted() {

  },
  methods: {
    searchData() {
      clearInterval(this.searchInterval);
      this.searchInterval = setInterval(() => {
        clearInterval(this.searchInterval);
        this.refresh();
        this.GetPermitTransactionList();
      }, 500);
    },
    clearSearch() {
      this.search = "";
      this.refresh();
      this.GetPermitTransactionList();
    },

    clickCallback: function (PageNo) {
      this.PageNo = PageNo;
      this.GetPermitTransactionList();
    },
    GetPermitTransactionList() {
      let payload = {
        PageNo: this.PageNo,
        PageRecord: this.PageRecord,
        end_date: this.end_date || moment().endOf("week").format("MM-DD-YYYY"),
        filter: this.search_status,
        search: this.search,
        start_date:
          this.start_date || moment().startOf("week").format("MM-DD-YYYY"),
        type_filter: this.search_by_type,
      };
      this.$store
        .dispatch("permitCheckPrizerList", {
          payload,
        })
        .then((response) => {
          if (response.res == 1) {
            this.openGlobalSnackbar(response.msg);
          }
        });
    },
    refresh() {
      this.PageNo = 1;
      if (this.$refs.refTransactionPagintation) {
        this.$refs.refTransactionPagintation.selected = 0;
      }
    },
    updateDate: function (data) {
      var dat = data.split(" - ");
      this.start_date = dat[0];
      this.end_date = dat[1];
      this.refresh();
      this.GetPermitTransactionList();
    },
    clearDate() {
      this.start_date = "";
      this.end_date = "";
      this.GetPermitTransactionList();
    },
  },
  beforeDestroy() {
    this.searchInterval && clearInterval(this.searchInterval);
  },
};
</script>

<style scoped>
input.md-input::placeholder {
  color: #fff !important;
}
</style>

