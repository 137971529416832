<template>
    <div>
        <Headers/>
        <router-view/>
        <Footer/>
    </div>
</template>

<script>
import Headers from './Header.vue'
import Footer from './Footer.vue'
export default {
    name:"lay-out",
    components:{Headers,Footer}
}
</script>

<style>

</style>